import React, { useEffect, useState } from "react";
import { Button, FormGroup, Form, Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import imageCompression from "browser-image-compression";
import { yupResolver } from "@hookform/resolvers/yup";
import swal from "sweetalert2";

import DefaultHeader from "../../components/Headers/DefaultHeader";
import * as userActions from "../../store/actions/userActions";
import { vendorValidationSchema } from "../../utilities/Schemas/user";
import useDebounce from "../../hooks/useDebounce";
import { get, post } from "../../store/lib/Api";
import { errorResponse } from "../../store/sagas/auth";
import { businessTypeOptions } from "../../utilities";

const InputField = ({
  type = "text",
  name,
  label,
  register,
  vendor,
  children,
  errors,
  ...rest
}) => {
  return (
    <FormGroup className="d-inline-block w-100 kmb-1">
      <div className="d-flex">
        <TextField
          {...rest}
          type={type}
          name={name}
          label={label}
          variant="outlined"
          size="small"
          fullWidth
          {...register(name)}
        />
        {children}
      </div>
      {errors?.[name]?.message && (
        <div className="text-danger">{errors?.[name]?.message}</div>
      )}
    </FormGroup>
  );
};

const formatSSN = (value) => {
  if (!value) return "";
  if (value.length <= 3) {
    return value;
  } else if (value.length <= 5) {
    return `${value.slice(0, 3)}-${value.slice(3)}`;
  } else {
    return `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
  }
};

const formatEIN = (value) => {
  let formattedValue = value.replace(/\D/g, "");
  if (formattedValue.length > 2) {
    formattedValue = `${formattedValue.slice(0, 2)}-${formattedValue.slice(2)}`;
  }
  return formattedValue.slice(0, 10);
};

const DEFAULT_PASSWORD = "DEFAULT_PASSWORD";

const CreateVendor = (props) => {
  const { location, history, auth, vendors, addVendor, updateVendor } = props;

  const vendorData = vendors.find((item) => item._id === location?.state);
  const [ssn, setSSN] = useState("");
  const [ein, setEIN] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const { register, handleSubmit, watch, setValue, formState, control } =
    useForm({
      defaultValues: {
        email: vendorData?.email ?? "",
        firstName: vendorData?.firstName ?? "",
        lastName: vendorData?.lastName ?? "",
        storeName: vendorData?.storeName ?? "",
        contact: vendorData?.contact ?? "",
        password: vendorData ? DEFAULT_PASSWORD : "",
        managerContact: vendorData?.managerContact ?? "",
        storeAddress: vendorData?.storeAddress ?? "",
        businessType:
          businessTypeOptions.find(
            (x) => x.value === vendorData?.businessType
          ) ?? "",
        w9: vendorData?.w9 ?? "",
      },
      resolver: yupResolver(vendorValidationSchema(!!vendorData)),
    });

  const storeAddress = watch("storeAddress");
  const addressInput = watch("addressInput");
  const businessType = watch("businessType");
  const w9Doc = watch("w9");
  const debouncedAddressValue = useDebounce(storeAddress, 500);

  const { errors, dirtyFields } = formState;

  useEffect(() => {
    vendorData?.ssn && setSSN(formatSSN(vendorData?.ssn));
    vendorData?.ein && setEIN(formatEIN(vendorData?.ein));
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedAddressValue.length < 3) return;
      try {
        const response = await get(
          `/address/places?address=${debouncedAddressValue}`,
          { "x-api-key": process.env.REACT_APP_X_API_KEY }
        );
        const results = response.map((prediction) => prediction.description);
        setSuggestions(results);
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address suggestions",
          icon: "error",
        });
      }
    };

    debouncedAddressValue && dirtyFields.storeAddress && fetchSuggestions();
  }, [debouncedAddressValue]);

  useEffect(() => {
    const fetchAddress = async () => {
      if (!addressInput) return;
      try {
        const response = await get(`/address/place?address=${addressInput}`, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        const { lat, lng } = response?.[0]?.geometry?.location ?? {};
        setValue("storeAddress", response?.[0]?.formatted_address ?? "");
        setValue("lat", lat);
        setValue("lng", lng);
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address",
          icon: "error",
        });
      }
    };

    addressInput && fetchAddress();
  }, [addressInput]);

  const onUpload = async (file) => {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/jpeg",
      quality: 0.8,
    };

    const fileType = file.name.split(".").at(-1).toLowerCase();
    const isHeifFile = fileType === "heif" || fileType === "heic";

    try {
      const formData = new FormData();
      let uploadedFile;

      if (isHeifFile) {
        formData.append("media", file);

        setIsUploading(true);
        const result = await post("/users/heif-converter", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        setIsUploading(false);

        uploadedFile = result?.data?.[0];
      } else {
        const compressedFile = await imageCompression(file, options);
        formData.append("media", compressedFile);

        setIsUploading(true);
        const result = await post("/users/upload-image", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        setIsUploading(false);

        uploadedFile = result?.data?.[0];
      }

      if (uploadedFile) {
        setValue("w9", uploadedFile);
      }
    } catch (error) {
      errorResponse(error);
    }
  };

  const handleSSNChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    if (rawValue.length <= 9) {
      setSSN(formatSSN(rawValue));
    }
  };

  const handleEINChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    if (rawValue.length <= 9) {
      setEIN(formatEIN(rawValue));
    }
  };

  const onSubmit = (formFields) => {
    let vendorObj = {
      firstName: formFields?.firstName,
      lastName: formFields?.lastName,
      email: formFields?.email,
      storeName: formFields?.storeName,
      contact: formFields?.contact,
      managerContact: formFields?.managerContact,
      storeAddress: formFields?.storeAddress,
      lat: formFields?.lat ?? null,
      lng: formFields?.lng ?? null,
      businessType: formFields?.businessType?.value,
      w9: formFields?.w9,
    };

    if (businessType?.value === "individual") {
      vendorObj = {
        ...vendorObj,
        ssn: ssn.replace(/\D/g, ""),
      };
    } else if (businessType?.value === "company") {
      vendorObj = {
        ...vendorObj,
        ein: ein.replace(/\D/g, ""),
      };
    }

    if (formFields?.password != DEFAULT_PASSWORD) {
      vendorObj = {
        ...vendorObj,
        password: formFields?.password,
      };
    }

    if (vendorData) {
      updateVendor(vendorData?._id, vendorObj, auth?.token);
    } else addVendor(vendorObj, auth?.token);
    history.push(`/dashboard/vendorstore`, "noRefetch");
  };

  const commonProps = {
    register,
    errors,
    control,
  };

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="8" md="12" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>{vendorData ? "Update" : "Add"} a vendor</h2>
                </div>
                <div className="position-relative">
                  <div className="new-product">
                    <div className="form-container position-relative">
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-100 d-flex flex-column"
                      >
                        <InputField
                          name="firstName"
                          label="First name"
                          {...commonProps}
                        />
                        <InputField
                          name="lastName"
                          label="Last name"
                          {...commonProps}
                        />
                        <InputField
                          name="email"
                          label="Email"
                          {...commonProps}
                        />
                        <InputField
                          type="password"
                          name="password"
                          label="Password"
                          {...commonProps}
                        />
                        <InputField
                          name="storeName"
                          label="Store name"
                          {...commonProps}
                        />
                        <InputField
                          name="contact"
                          label="Contact"
                          {...commonProps}
                        />
                        <InputField
                          name="managerContact"
                          label="Manager Contact"
                          {...commonProps}
                        />
                        <Controller
                          control={control}
                          name="storeAddress"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              value={value}
                              options={suggestions ?? []}
                              fullWidth
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Address"
                                  variant="outlined"
                                  fullWidth
                                  onChange={onChange}
                                />
                              )}
                              onChange={(_, value) => {
                                onChange(value);
                                setValue("addressInput", value);
                              }}
                            />
                          )}
                        />
                        <Controller
                          name="businessType"
                          control={control}
                          defaultValue=""
                          render={({ field: { value, onChange } }) => {
                            return (
                              <Autocomplete
                                value={value}
                                className="mt-4"
                                options={businessTypeOptions}
                                getOptionLabel={(x) => x.label}
                                size="small"
                                onChange={(_, selectedOption) => {
                                  onChange(selectedOption ?? null);
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Business Type"
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            );
                          }}
                        />
                        {businessType?.value === "individual" && (
                          <TextField
                            label="Social Security Number (SSN)"
                            variant="outlined"
                            className="mt-4"
                            size="small"
                            required
                            fullWidth
                            value={ssn}
                            onChange={handleSSNChange}
                          />
                        )}
                        {businessType?.value === "company" && (
                          <TextField
                            label="Employer Identification Number (EIN)"
                            variant="outlined"
                            className="mt-4"
                            size="small"
                            fullWidth
                            required
                            value={ein}
                            onChange={handleEINChange}
                          />
                        )}

                        <FormGroup className="mb-3">
                          <div className="mt-10">
                            <label className="font-18">W9 Document:</label>
                          </div>
                          <Controller
                            name="w9"
                            control={control}
                            render={() => (
                              <input
                                type="file"
                                onChange={(e) => onUpload(e.target.files[0])}
                              />
                            )}
                          />
                          {isUploading ? (
                            <CircularProgress
                              className="mt-2"
                              variant="indeterminate"
                            />
                          ) : w9Doc?.url ? (
                            <img
                              className="rounded mt-2"
                              width={150}
                              height={100}
                              src={w9Doc?.url}
                            />
                          ) : (
                            errors.w9?.message && (
                              <div className="text-danger">
                                {errors.w9?.message}
                              </div>
                            )
                          )}
                        </FormGroup>
                        <div className="d-flex gap-6 mt-3">
                          <Button
                            className="w-100"
                            color="danger"
                            type="button"
                            onClick={() =>
                              history.push(
                                `/dashboard/vendorstore`,
                                "noRefetch"
                              )
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            className="w-100"
                            color={vendorData ? "success" : "primary"}
                            type="submit"
                          >
                            {vendorData ? "Update" : "Add"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    vendors: state.user.vendors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVendor: (payload, token) =>
      dispatch(userActions.addVendor(payload, token)),
    updateVendor: (id, payload, token) =>
      dispatch(userActions.updateVendor(id, payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVendor);
