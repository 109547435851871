import React from "react";
import { FaEye, FaEdit, FaTrash, FaCheck, FaWindowClose } from "react-icons/fa";
import { Row, Col, Table } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Chip, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Spinner } from "reactstrap";
import placeholder from "../../../assets/img/Placeholder.png";

import "./style.css";
import * as Const from "../../../utilities";

const PopUpModal = ({
  modalIsOpen,
  closeModal,
  product,
  handleEdit,
  handleDelete,
}) => {
  return (
    <Dialog
      maxWidth="xl"
      open={modalIsOpen}
      onClose={closeModal}
      aria-hidden={false}
      aria-label="Preview Modal"
    >
      <DialogTitle>
        <Close onClick={closeModal} className="close-btn" />
      </DialogTitle>
      <DialogContent className="pb-4">
        <Row>
          <Col sm="12" md="5">
            <div className="images">
              <img
                className="w-100 h-100 rounded"
                src={
                  product?.productPic?.[0]?.url
                    ? `${product?.productPic?.[0]?.url}`
                    : placeholder
                }
                alt=""
              />
            </div>
          </Col>
          <Col sm="12" md="7">
            <div className="product mt-2">
              <p>
                <em>{product && product.sku ? product.sku : "No keyword"}</em>{" "}
                &nbsp; &nbsp;
                <Chip
                  label={
                    Const.productStatusOptions.find(
                      (option) => option?.value == product?.status
                    )?.label
                  }
                />
              </p>
              <div dangerouslySetInnerHTML={{ __html: product.name }} />
              <h2>
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "#fb6340",
                  }}
                >
                  ${product.price}
                  <br />
                </span>
                <span
                  style={{
                    color: "Green",
                  }}
                >
                  ${product.finalPrice}
                </span>
                &nbsp; &nbsp; <FaEye size={"17px"} />
                &nbsp;{product.views}{" "}
              </h2>
              {product.stock > 0 ? (
                <span className="inStock">
                  <FaCheck />
                  {product.stock} in stock
                </span>
              ) : (
                <span className="outStock">
                  <FaWindowClose />
                  {product.stock} in stock
                </span>
              )}
              <div dangerouslySetInnerHTML={{ __html: product.description }} />
              <div className="buttons">
                <button
                  onClick={() => handleEdit(product._id)}
                  className="btn-sm btn-info editModal"
                >
                  Edit <FaEdit />
                </button>
                <button
                  onClick={() => handleDelete(product)}
                  className="btn-sm btn-danger deleteModal"
                >
                  Delete <FaTrash />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

const normalView = (props) => {
  return (
    <Row>
      <Col sm="5">
        <div className="images">
          <img
            className="image"
            src={`${props.product?.productPic?.[0]?.url}`}
            alt=""
          />
        </div>
      </Col>
      <Col sm="7">
        <div className="product">
          <p>
            <em>
              {props.product && props.product.sku
                ? props.product.sku
                : "No keyword"}
            </em>{" "}
            &nbsp; &nbsp;
            {props.product.isPublish ? (
              <span
                className="badge badge-success"
                style={{ fontSize: "14px" }}
              >
                Published
              </span>
            ) : (
              <span
                className="badge badge-warning"
                style={{ fontSize: "14px" }}
              >
                Pending
              </span>
            )}
          </p>
          <div dangerouslySetInnerHTML={{ __html: props.product.name }} />
          <h2>
            <span
              style={{
                textDecoration: "line-through",
                color: "#fb6340",
              }}
            >
              ${props.product.price}
              <br />
            </span>
            <span
              style={{
                color: "Green",
              }}
            >
              ${props.product.finalPrice}
            </span>
            &nbsp; &nbsp; <FaEye size={"17px"} />
            &nbsp;{props.product.views}{" "}
          </h2>
          {props.product.stock > 0 ? (
            <span className="inStock">
              <FaCheck />
              {props.product.stock} in stock
            </span>
          ) : (
            <span className="outStock">
              <FaWindowClose />
              {props.product.stock} in stock
            </span>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: props.product.description }}
          />
          <div className="buttons">
            <button
              onClick={() => props.handleEdit(props.product._id)}
              className="btn-sm btn-info editModal"
            >
              Edit <FaEdit />
            </button>
            <button
              onClick={() => props.handleDelete(props.product)}
              className="btn-sm btn-danger deleteModal"
            >
              Delete <FaTrash />
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
const orderItems = (orderItems) => {
  return (
    <Row>
      <Table>
        <thead className="thead-light">
          <tr>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              NAME
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              QUANTITY
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              IN STOCK
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              $ PRICE
            </th>
            <th
              style={{ fontSize: "13px", fontWeight: "bolder", color: "black" }}
            >
              $ TOTAL
            </th>
          </tr>
        </thead>
        <tbody style={{ fontWeight: "bolder" }}>
          {orderItems.product &&
            orderItems.product.map((item) => {
              return (
                <tr>
                  <td>{item.productId.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.productId.stock}</td>

                  <td>
                    <span
                      style={{
                        color: "#black",
                        fontSize: "15px",
                      }}
                    >
                      <b>
                        {" "}
                        ${Math.round(item.totalPrice / item.quantity)} / piece
                      </b>{" "}
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color: "#black",
                        fontSize: "15px",
                      }}
                    >
                      <b> ${item.totalPrice}</b> <br />
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Row>
  );
};

const RefundForm = (props) => {
  return (
    <Form>
      {props.loading && <Spinner style={{ width: "3rem", height: "3rem" }} />}
      <FormGroup>
        <Label for="exampleEmail">REASON</Label>
        <Input
          type="text"
          name="reason"
          placeholder="Reason to refund"
          value={props.refund.reason}
          onChange={props.handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="exampleText">AMOUNT</Label>
        <Input
          type="number"
          name="amount"
          max={props.order?.amount}
          maxLength={props.order?.amount}
          placeholder="Refund amount"
          value={props.refund.amount}
          onChange={props.handleInputChange}
        />
      </FormGroup>
      <Button style={{ color: "red" }} onClick={props.handleRefund}>
        {`Refund $${props.refund.amount}`}
      </Button>
    </Form>
  );
};

export default PopUpModal;
