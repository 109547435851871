import * as types from "./../types/auth";
import { rootReducers } from "./index";

const initialState = {
  isAuthenticated: false,
  user: {},
  token: "",
  profilePic: null,
  role: "",
  error: "",
  loading: false,
};

const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      const { token, user } = action.payload ?? {};
      const { userId, role, firstName, lastName, email, profilePic, accesses } =
        user ?? {};
      return {
        ...state,
        isAuthenticated: true,
        user: userId,
        token,
        role,
        profilePic,
        name: firstName + " " + lastName,
        email,
        loading: false,
        accesses: accesses ?? [],
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.LOGIN:
      return {
        ...state,
        loading: false,
      };
    case types.LOGOUT_USER: {
      // state = undefined;
      // return rootReducers(state, action);
      return {
        state: undefined,
        loading: false,
        // isAuthenticated: false,
        // user: {},
        // token: "",
        // role: ""
      };
    }
    case types.RESET_AUTH: {
      return initialState;
    }
    case types.GET_ME_SUCCESS: {
      const { _id, role, firstName, lastName, email, profilePic, accesses } =
        action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user: _id,
        role,
        name: firstName + " " + lastName,
        email,
        profilePic,
        loading: false,
        accesses: accesses ?? [],
      };
    }
    case types.UPDATE_PROFILE_SUCCESS: {
      const { profilePic } = action.payload;
      return {
        ...state,
        profilePic,
      };
    }
    default:
      return state;
  }
};

export default authReducers;
