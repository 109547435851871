import React, { useRef } from "react";
import { BiPencil } from "react-icons/bi";
import * as authActions from "../../store/actions/authActions";
import { profilePlaceholder } from "../../utilities";
import { useDispatch, useSelector } from "react-redux";
import ImageCropper from "../ImageCropper";

const Profile = () => {
  const dispatch = useDispatch();
  const profileInputRef = useRef(HTMLInputElement);
  const { token, email, profilePic } = useSelector((state) => state.auth);

  const onUpload = (file) => {
    const formData = new FormData();
    formData.append("media", file);
    dispatch(authActions.updateProfile(formData, token));
  };

  const removeProfile = () => {
    const formData = new FormData();
    formData.append("removeProfile", true);
    dispatch(authActions.updateProfile(formData, token));
  };

  return (
    <div className="mb-3">
      <div className="d-flex gap-12 align-items-center">
        <div
          className="profile-pic-wrapper cursor-pointer"
          onClick={() => profileInputRef.current?.click()}
        >
          <div className="overlay">
            <div
              className="position-absolute h-100 w-100"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            />
            <BiPencil
              style={{ height: 24, width: 24 }}
              className="text-white position-absolute"
            />
          </div>
          <img
            className="w-100 h-100"
            src={profilePic || profilePlaceholder}
            alt="Profile"
          />
        </div>
        <h4 style={{ overflow: "hidden", textOverflow: "ellipsis", margin: 0 }}>
          {email}
        </h4>
      </div>
      {profilePic?.includes("cloudinary") && (
        <span onClick={removeProfile} className="cursor-pointer">
          Remove
        </span>
      )}
      <ImageCropper inputRef={profileInputRef} onUpload={onUpload} />
    </div>
  );
};

export default Profile;
