import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormGroup } from "@material-ui/core";
import { CKEditor } from "ckeditor4-react";
// import { Editor } from "@tinymce/tinymce-react";

import { addMenuContent } from "../../store/actions/settingActions";

const H5Editor = ({ menuData, onClose }) => {
  const [content, setContent] = useState(menuData?.content?.content ?? "");
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const onConfirm = () => {
    dispatch(
      addMenuContent({ payload: { menuId: menuData?._id, content }, token })
    );
    onClose();
  };

  return (
    <Card className="shadow">
      <CardContent>
        <div className="d-flex gap-24 align-items-center mb-4">
          <Button className="bg-primary text-white px-3" onClick={onClose}>
            Back
          </Button>
          <h3 className="m-0">{menuData?.name}</h3>
        </div>
        <FormGroup className="mb-3">
          <div className="mt-10">
            <label className="font-18">Content:</label>
          </div>
          <CKEditor
            initData={content}
            config={{
              versionCheck: false,
              placeholder: "Write your here...",
              height: 400,
              extraPlugins: 'font',
              font_names: 'Comic Sans MS;Courier New;Helvetica;Impact;Tahoma;Times New Roman;Verdana;',
              fontSize_sizes: '8/8px;10/10px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;30/30px;32/32px;34/34px;36/36px;38/38px;40/40px;',
            }}
            onChange={(event) => setContent(event.editor.getData())}
          />
          {/* <Editor
            apiKey={process.env.REACT_APP_TINYMCE_API}
            initialValue={menuData?.content?.content ?? ""}
            init={{
              menubar: true,
              branding: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
              placeholder: "Write here...",
              height: 400,
              directionality: "ltr",
            }}
            onEditorChange={(content) => setContent(content)}
          /> */}
        </FormGroup>
        <div className="d-flex justify-content-between gap-4 mt-4">
          <Button
            type="submit"
            fullWidth
            onClick={onConfirm}
            className="bg-success text-white"
          >
            PUBLISH
          </Button>
          <Button
            type="button"
            fullWidth
            className="bg-danger text-white"
            onClick={onClose}
          >
            CANCEL
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default H5Editor;
