const { useState, useEffect, useCallback } = require("react");

const useScreenResolution = () => {
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    scrollY: window.scrollY,
  });

  const handleOnResize = useCallback(({ currentTarget }) => {
    setResolution((prevState) => ({
      ...prevState,
      width: currentTarget.innerWidth,
      height: currentTarget.innerHeight,
    }));
  }, []);

  const handleOnScroll = useCallback(() => {
    setResolution((prevState) => ({
      ...prevState,
      scrollY: window.scrollY,
    }));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleOnResize);
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("resize", handleOnResize);
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, []);

  return {
    width: resolution.width,
    height: resolution.height,
    scrollY: resolution.scrollY,
    isMobile: resolution.width < 480,
    isBigMobile: resolution.width < 640,
    isMedium: resolution.width < 768,
  };
};

export default useScreenResolution;
