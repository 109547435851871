export const LOADING = "LOADING";
export const LOADING_SUCCESS = "LOADING_SUCCESS";
export const GET_VENDORS = "GET_VENDORS";
export const GET_SINGLE_VENDOR = "GET_SINGLE_VENDOR";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_SINGLE_VENDOR_SUCCESS = "GET_SINGLE_VENDOR_SUCCESS";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_PERMANENTLY = "DELETE_USER_PERMANENTLY";
export const DELETE_USER_PERMANENTLY_SUCCESS =
  "DELETE_USER_PERMANENTLY_SUCCESS";
export const DELETE_VENDOR = "DELETE_VENDOR";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_VENDOR_SUCCESS = "ACTIVATE_VENDOR_SUCCESS";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA = "GET_MEDIA";
export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const TRASH_MEDIA = "TRASH_MEDIA";
export const TRASH_MEDIA_SUCCESS = "TRASH_MEDIA_SUCCESS";
export const LIST_USER_FAILED = "LIST_USER_FAILED";
export const ADD_UPDATE_USER_FAILED = "ADD_UPDATE_USER_FAILED";

export const UPDATE_VENDOR_STATUS = "UPDATE_VENDOR_STATUS";
export const UPDATE_VENDOR_STATUS_SUCCESS = "UPDATE_VENDOR_STATUS_SUCCESS";
export const REMOVE_SUCCESS_MSG = "REMOVE_SUCCESS_MSG";
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const UPDATE_USER_BY_ADMIN = "UPDATE_USER_BY_ADMIN";
export const UPDATE_USER_BY_ADMIN_SUCCESS = "UPDATE_USER_BY_ADMIN_SUCCESS";
export const ADD_FLAG_TO_MEDIA = "ADD_FLAG_TO_MEDIA";
export const ADD_FLAG_TO_MEDIA_SUCCESS = "ADD_FLAG_TO_MEDIA_SUCCESS";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";

export const UNREAD_NOTIFICATION = "UNREAD_NOTIFICATION";
export const UNREAD_NOTIFICATION_SUCCESS = "UNREAD_NOTIFICATION_SUCCESS";

export const MARK_NOTIFICATION = "MARK_NOTIFICATION";
export const MARK_NOTIFICATION_SUCCESS = "MARK_NOTIFICATION_SUCCESS";

export const MARK_ALL_NOTIFICATIONS = "MARK_ALL_NOTIFICATIONS";
export const MARK_ALL_NOTIFICATIONS_SUCCESS = "MARK_ALL_NOTIFICATIONS_SUCCESS";

export const DELETE_STORE = "DELETE_STORE";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";

export const TRASH_STORE = "TRASH_STORE";
export const TRASH_STORE_SUCCESS = "TRASH_STORE_SUCCESS";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";

export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";

export const ADD_VENDOR = "ADD_VENDOR";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";

export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";

export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";

export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";

export const RESET_USER = "RESET_USER";
export const USER_FAILED = "USER_FAILED";
export const SET_REDIRECT_TO = "SET_REDIRECT_TO";

export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";

export const GET_SALES_REPORT = "GET_SALES_REPORT";
export const GET_SALES_REPORT_SUCCESS = "GET_SALES_REPORT_SUCCESS";

export const GET_DASHBOARD_COUNT = "GET_DASHBOARD_COUNT";
export const GET_DASHBOARD_COUNT_SUCCESS = "GET_DASHBOARD_COUNT_SUCCESS";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";