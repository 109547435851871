/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Headers/DefaultHeader";
import SearchField from "./../../components/SearchFields/index";
import Paginator from "../Pagination/index";
import { getReviews } from "./../../store/actions/orderActions";
import { formatDate } from "../../utilities/index";

const Reviews = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [reviewData, setReviewData] = useState([]);
  const [page, setPage] = useState(1);
  const { reviews } = useSelector((state) => state.order);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getReviews(token));
  }, []);

  useEffect(() => {
    setReviewData(reviews);
  }, [reviews]);

  const handleClickActivity = (id) => {
    history.push(`/dashboard/orders/activity/${id}`, id);
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const startIndex = (page - 1) * 10;
  const endIndex = startIndex + 10;

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Reviews</h3>
              </CardHeader>
              <SearchField />

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ORDER</th>
                    <th scope="col">AUTHOR</th>
                    <th scope="col">COMMENT</th>
                    {/* <th scope="col">RATING</th> */}
                    <th scope="col">SUBMITTED DATE</th>
                    {/* <th scope="col">ACTIONS</th> */}
                  </tr>
                </thead>
                <tbody>
                  {reviewData
                    ?.splice(startIndex, endIndex)
                    ?.map((review, i) => {
                      return (
                        <tr key={i}>
                          <td
                            className="text-blue cursor-pointer"
                            onClick={() =>
                              handleClickActivity(review?.orderItemId)
                            }
                          >
                            #{review?.orderItem?.orderNo}
                          </td>
                          <td>
                            {review.vendorInfo?.firstName}{" "}
                            {review.vendorInfo?.lastName}
                          </td>
                          <td
                            style={{
                              minWidth: 330,
                              maxWidth: 400,
                              whiteSpace: "pre-line",
                            }}
                          >
                            {review.vendorQuestionnaire?.suggestions?.value}
                          </td>
                          <td>{formatDate(review.createdAt)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Paginator
                  onPageChange={onPageChange}
                  page={page}
                  perPage={10}
                  total={reviewData?.length}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Reviews;
