import React from "react";
import { Link } from "react-router-dom";
import { FiberManualRecord } from "@material-ui/icons";
import moment from "moment";

import { getNotificationMessageAndLink } from "./notifications";
import {
  markNotification,
  unreadNotificationSuccess,
} from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";

function NotificationItem({ token, notification, setShowNotification, type }) {
  const dispatch = useDispatch();
  const { notificationCount } = useSelector((state) => state.user);
  const notificationData = getNotificationMessageAndLink(notification);
  const { message, link, clientReadStatus, createdAt } = notificationData ?? {};

  const handleOnClick = () => {
    if (clientReadStatus == "UNREAD") {
      dispatch(markNotification(token, notification._id));
      dispatch(
        unreadNotificationSuccess({
          ...notificationCount,
          [type]:
            (notificationCount?.[type] || 0) >= 1
              ? (notificationCount?.[type] || 1) - 1
              : 0,
        })
      );
    }
    setShowNotification && setShowNotification(false);
  };

  if (!notificationData) {
    return <></>;
  }

  return link ? (
    <Link onClick={handleOnClick} to={link} className="wrapper">
      <div className="item">
        <div className="title-wrapper">
          <div dangerouslySetInnerHTML={{ __html: message }} />
          <div className="text-xs">{moment(createdAt).fromNow()}</div>
        </div>
        {clientReadStatus == "UNREAD" && (
          <FiberManualRecord className="unread-dot" />
        )}
      </div>
    </Link>
  ) : (
    <div onClick={handleOnClick} className="item">
      <div className="title-wrapper">
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <div className="text-xs">{moment(createdAt).fromNow()}</div>
      </div>
      {clientReadStatus == "UNREAD" && (
        <FiberManualRecord className="unread-dot" />
      )}
    </div>
  );
}

export default NotificationItem;
