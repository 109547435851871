import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./../types/product";
import * as service from "./../services/product";
import * as actions from "./../actions/productActions";
import { push } from "react-router-redux";
import { errorResponse, successResponse } from "./auth";

function* getProduct({ token, query }) {
  try {
    const result = yield call(service.products, token, query);
    if (result.status === 200) {
      yield put(actions.getProductsSuccess(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* addViews(action) {
  const { id, token } = action.payload;
  try {
    const result = yield call(service.addView, id, token);
    if (result.status === 200) {
      yield put(actions.addViewSuccess(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* getMyProducts(action) {
  const { user } = action;
  try {
    const result = yield call(service.getMyProducts, user);
    if (result.status === 200) {
      yield put(actions.getMyProductsSuccess(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* addToStore(action) {
  const { id, token } = action.payload;
  try {
    const result = yield call(service.addToStore, id, token);
    if (result.status === 200) {
      yield put(actions.addToStoreSuccess(result.data.data));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}
function* addProduct(action) {
  try {
    const { product, token } = action.payload;
    const result = yield call(service.addProduct, product, token);
    if (result.status === 201) {
      yield put(actions.addProductSuccess(result.data.data));
      yield put(push("/dashboard/products"));
    } else if (result.status === 404 || result.status === 500) {
      yield put(actions.addUpdateProductFailed(result.data.message));
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.addUpdateProductFailed(error.toString()));
  }
}

function* deleteProduct(action) {
  try {
    const { id, token } = action.payload;
    yield call(service.deleteProduct, id, token);
    yield put(actions.deleteProductSuccess({ _id: id }));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.listProductFailed(error.toString()));
  }
}
function* updateProduct(action) {
  try {
    const { id, product, token } = action.payload;
    const result = yield call(service.updateProduct, id, product, token);
      yield put(actions.updateProductSuccess());
      successResponse(result);
      yield put(push("/dashboard/products"));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
    yield put(actions.addUpdateProductFailed(error.toString()));
  }
}

function* addFlag({ id, payload, token }) {
  try {
    const result = yield call(service.addFlag, id, payload, token);
    yield put(actions.addFlagSuccess(result.data.data));
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* trashProduct({ id, payload, token }) {
  try {
    const result = yield call(service.trashProduct, id, payload, token);
    yield put(actions.trashProductSuccess({ _id: id }));
    successResponse(result);
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(push("/auth/login"));
    }
    errorResponse(error);
  }
}

function* changeStatus({ id, status, token }) {
  try {
    const result = yield call(service.changeStatus, id, status, token);
    yield put(actions.changeStatusSuccess({ id, status }));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
  }
}

export default function* productWatcher() {
  yield takeLatest(types.GET_PRODUCTS, getProduct);
  yield takeLatest(types.ADD_PRODUCT, addProduct);
  yield takeLatest(types.DELETE_PRODUCT, deleteProduct);
  yield takeLatest(types.UPDATE_PRODUCT, updateProduct);
  yield takeLatest(types.GET_MY_PRODUCT, getMyProducts);
  yield takeLatest(types.ADD_TO_STORE, addToStore);
  yield takeLatest(types.ADD_PRODUCT_VIEW, addViews);
  yield takeLatest(types.ADD_FLAG, addFlag);
  yield takeLatest(types.TRASH_PRODUCT, trashProduct);
  yield takeLatest(types.CHANGE_STATUS, changeStatus);
}
