import { activityCustomDate } from "../../utilities";

export const NotificationType = {
  NEW_CUSTOMER: "NEW_CUSTOMER",
  NEW_VENDOR: "NEW_VENDOR",
  VENDOR_APPROVED: "VENDOR_APPROVED",
  UPDATE_VENDOR: "UPDATE_VENDOR",
  NEW_PRODUCT: "NEW_PRODUCT",
  PRODUCT_APPROVED: "PRODUCT_APPROVED",
  PRODUCT_CANCELLED: "PRODUCT_CANCELLED",
  NEW_ARTICLE: "NEW_ARTICLE",
  ARTICLE_PUBLISHED: "ARTICLE_PUBLISHED",
  ARTICLE_UNPUBLISHED: "ARTICLE_UNPUBLISHED",
  ADMIN_ORDER_CANCEL_REQUEST: "ADMIN_ORDER_CANCEL_REQUEST",
  ADMIN_ORDER_CANCEL_REQUEST_APPROVED: "ADMIN_ORDER_CANCEL_REQUEST_APPROVED",
  ADMIN_ORDER_CANCELLED: "ADMIN_ORDER_CANCELLED",
  CUSTOMER_REVIEW: "CUSTOMER_REVIEW",
  VENDOR_REVIEW: "VENDOR_REVIEW",
  NEW_COUPON: "NEW_COUPON",
  DELETE_COUPON: "DELETE_COUPON",
  NEW_USER_WITH_ROLE: "NEW_USER_WITH_ROLE",
};

export const getNotificationMessageAndLink = (notification) => {
  const {
    type,
    article,
    vendor,
    user,
    client,
    product,
    orderItem,
    whatChanged,
    createdAt,
  } = notification;

  switch (type) {
    case NotificationType.NEW_CUSTOMER: {
      return {
        ...notification,
        message: `${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        } registered on ${activityCustomDate(createdAt)}`,
        link: `/dashboard/users?id=${notification?.userId}`,
      };
    }
    case NotificationType.NEW_VENDOR: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        } recently joined as vendor`,
        link: `/dashboard/vendorstore?id=${notification?.vendorId}`,
      };
    }
    case NotificationType.VENDOR_APPROVED: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        }'s vendor account approved`,
        link: `/dashboard/vendorstore?id=${notification?.vendorId}`,
      };
    }
    case NotificationType.UPDATE_VENDOR: {
      if (whatChanged?.storeAddress) {
        return {
          ...notification,
          message: `${vendor?.firstName ?? ""} ${
            vendor?.lastName ?? ""
          } has changed the address for the ${vendor?.storeName} <br> from ${
            whatChanged?.storeAddress?.from
          } <br> to ${
            whatChanged?.storeAddress?.to
          } on: <br> ${activityCustomDate(createdAt)}`,
          link: `/dashboard/vendorstore?id=${notification?.vendorId}`,
        };
      }
    }
    case NotificationType.NEW_PRODUCT: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        } has added new product: <br> ${product?.name}`,
        link: `/dashboard/products?id=${notification?.productId}`,
      };
    }
    case NotificationType.PRODUCT_APPROVED: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        }'s product has been approved <br> <b>${
          product?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/products?id=${notification?.productId}`,
      };
    }
    case NotificationType.PRODUCT_CANCELLED: {
      return {
        ...notification,
        message: `${vendor?.firstName ?? ""} ${
          vendor?.lastName ?? ""
        }'s product has been cancelled <br> <b>${
          product?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/products?id=${notification?.productId}`,
      };
    }
    case NotificationType.NEW_ARTICLE: {
      return {
        ...notification,
        message: `${client?.firstName ?? ""} ${
          client?.lastName ?? ""
        } has added new article <b>${
          article?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/articles?id=${notification?.articleId}`,
      };
    }
    case NotificationType.ARTICLE_PUBLISHED: {
      return {
        ...notification,
        message: `${client?.firstName ?? ""} ${
          client?.lastName ?? ""
        } has published new article <b>${
          article?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/articles?id=${notification?.articleId}`,
      };
    }
    case NotificationType.ARTICLE_UNPUBLISHED: {
      return {
        ...notification,
        message: `${client?.firstName ?? ""} ${
          client?.lastName ?? ""
        } has unpublished article <b>${
          article?.name
        }</b> on: <br> ${activityCustomDate(createdAt)}`,
        link: `/dashboard/articles?id=${notification?.articleId}`,
      };
    }
    case NotificationType.ADMIN_ORDER_CANCEL_REQUEST: {
      return {
        ...notification,
        message: `${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        } has requested cancellation for Order ID: <b>#${
          orderItem?.orderNo
        }</b>`,
        link: `/dashboard/orders/activity/${notification?.orderItemId}`,
      };
    }
    case NotificationType.ADMIN_ORDER_CANCEL_REQUEST_APPROVED: {
      return {
        ...notification,
        message: `You have approved order cancellation request for Order ID: <b>#${orderItem?.orderNo}</b>`,
        link: `/dashboard/orders/activity/${notification?.orderItemId}`,
      };
    }
    case NotificationType.ADMIN_ORDER_CANCELLED: {
      return {
        ...notification,
        message: `Order ID: <b>#${orderItem?.orderNo}</b> has been cancelled.`,
        link: `/dashboard/orders/activity/${notification?.orderItemId}`,
      };
    }
    case NotificationType.NEW_USER_WITH_ROLE: {
      return {
        ...notification,
        message: `New member ${user?.firstName ?? ""} ${
          user?.lastName ?? ""
        } joined in the panel`,
        link: `/dashboard/users-with-roles?id=${notification?.userId}`,
      };
    }
    default:
      return;
  }
};
