import React from "react";
import { Button, Chip } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import { OrderStatus, allStatus, formatDate } from "../../../utilities";
import { get } from "../../../store/lib/Api";

const Item = (props) => {
  const {
    auth,
    item,
    setOrderInfo,
    setShowCancelRequestModal,
    handleGeneratePdf,
    history,
  } = props;
  const product = item?.product ?? {};

  const orderTotal = (
    (item?.totalPrice || 0) + (item?.addons?.[0]?.totalPrice || 0)
  ).toFixed(2);

  const status = allStatus.find((status) => status.status === item?.status);

  const onContact = (email) => {
    get(`/users/hubspotContact?email=${email}`, {
      Authorization: `Bearer ${auth?.token}`,
    }).then((data) => {
      if (data?.contactId) {
        window.open(
          `https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_ID}/contact/${data?.contactId}`,
          "_blank"
        );
      }
    });
  };

  return (
    <div className="item-container">
      <div className="order-section">
        <div className="product-section">
          <div className="product-container">
            {product?.productPic?.length > 0 ? (
              <img
                src={product?.productPic[0]?.url ?? product?.productPic[0]?.img}
                width={100}
                height={100}
                alt="Product Image"
              />
            ) : (
              <img
                src="https://www.rkmarblesindia.com/wp-content/uploads/2021/03/Ruby-red-Granite-4.jpg"
                className="w-100"
                alt="No found"
              />
            )}
            <div className="product-details-section">
              <div className="d-flex">
                <div
                  className="mr-2"
                  dangerouslySetInnerHTML={{ __html: product?.name }}
                />
                <Chip
                  size="small"
                  style={{
                    fontSize: 13,
                    backgroundColor: status?.color,
                    color: "white",
                  }}
                  label={status?.label}
                />
              </div>
              <span
                style={{
                  color: "#81cdc6",
                  textDecoration: "underline",
                  width: "fit-content",
                }}
                className="cursor-pointer"
                onClick={() => handleGeneratePdf(item?._id)}
              >
                View invoice
              </span>
            </div>
          </div>
        </div>
        <div className="additional-details">
          <div className="order-total">
            <div className="order-date">
              <div className="slightBold">Order Total</div>
              <div className="date-font">
                <span>${orderTotal}</span>
              </div>
            </div>
          </div>
          <div className="order-date">
            <div className="slightBold">Order ID</div>
            <div className="date-font">
              <span>#{item?.orderNo}</span>
            </div>
          </div>
          <div className="order-on">
            <div className="order-date">
              <div className="slightBold">Ordered On</div>
              <div className="date-font">
                <span>{formatDate(item?.createdAt)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="address-section">
        <div className="address">
          <div className="text-nowrap mr-2">Shipping Address</div>
          <p className="p-0 m-0">{item?.shippingAddressInfo?.address}</p>
        </div>
        <div className="d-flex flex-column justify-content-end">
          {item?.status == OrderStatus.CANCELLED ? (
            <span className="text-underline mb-2 text-right">
              Order Cancelled
            </span>
          ) : item?.cancelRequest?.approved ? (
            <span className="text-underline mb-2 text-right">
              Cancel Request Approved
            </span>
          ) : item?.cancelRequest ? (
            <span
              className="text-underline mb-2 cursor-pointer text-right"
              onClick={() => {
                setOrderInfo(item);
                setShowCancelRequestModal(true);
              }}
            >
              Approve Cancel Request
            </span>
          ) : (
            <></>
          )}
          <div className="actions d-flex flex-wrap">
            <Button
              style={{ background: "#3B84CD" }}
              className="customer-btn mr-2 px-4 border-0 text-white"
              onClick={() => onContact(item?.user?.email)}
            >
              Contact customer
            </Button>
            <Button
              style={{ background: "#0B76E0" }}
              className="vendor-btn mr-2 px-4 border-0 text-white"
              onClick={() => onContact(item?.owner?.email)}
            >
              Contact vendor
            </Button>
            <Button
              style={{ background: "#28a99e" }}
              className="activity-btn px-4 border-0 text-white"
              onClick={() =>
                history.push(`/dashboard/orders/activity/${item?._id}`, {
                  id: item?.user?._id,
                  from: "user",
                })
              }
            >
              Activity
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Item);
