import React from "react";
import { Row, Col } from "reactstrap";
import { FaEye, FaTrash, FaEdit } from "react-icons/fa";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { BiLike } from "react-icons/bi";

import "./style.css";
import placeholder from "../../../assets/img/Placeholder.png";

const PopUpModal = ({
  modalIsOpen,
  closeModal,
  article,
  handleEdit,
  handleDelete,
}) => {
  return (
    <Dialog
      maxWidth="xl"
      open={modalIsOpen}
      onClose={closeModal}
      aria-hidden={false}
      aria-label="Preview Modal"
    >
      <DialogTitle>
        <Close onClick={closeModal} className="close-btn" />
      </DialogTitle>
      <DialogContent className="pb-4">
        <Row>
          <Col sm="12" md="5">
            <div className="images">
              <img
                className="w-100 h-100 rounded"
                src={
                  article?.cover?.url ? `${article.cover?.url}` : placeholder
                }
                alt=""
              />
            </div>
          </Col>
          <Col sm="12" md="7">
            <div className="product">
              <p>
                <em>
                  <strong>
                    {article && article.name
                      ? `Article: ${article.name.toUpperCase()}`
                      : ""}
                  </strong>
                </em>
              </p>
              <h2>
                {article.status === "published" ? (
                  <span
                    className="badge badge-success"
                    style={{ fontSize: "14px" }}
                  >
                    Published
                  </span>
                ) : (
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: "14px" }}
                  >
                    Pending
                  </span>
                )}
                <div className="d-flex align-items-center">
                  <FaEye size={"20px"} className="mr-2" /> {article.views}
                </div>
                <div className="d-flex align-items-center">
                  <BiLike size={"20px"} className="mr-2" /> {article.likes}
                </div>
              </h2>
              <p className="desc">{article.content}</p>
              <div className="d-flex gap-4">
                <button
                  onClick={() => handleEdit(article._id)}
                  className="btn-sm btn-info editModal"
                >
                  Edit <FaEdit />
                </button>
                <button
                  onClick={() => handleDelete(article)}
                  className="btn-sm btn-danger deleteModal"
                >
                  Delete <FaTrash />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpModal;
