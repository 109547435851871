import React, { createRef, useState } from "react";
import Cropper from "react-cropper";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import "cropperjs/dist/cropper.css";
import { Close } from "@material-ui/icons";
import { base64ToFile } from "../utilities";

const ImageCropper = ({ inputRef, onUpload }) => {
  const [originalFile, setOriginalFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const cropperRef = createRef();

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setOriginalFile(files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setProfileImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      return cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
    }
  };

  const onCancel = () => {
    setOriginalFile(null);
    setProfileImage(null);
  };

  const onCrop = async () => {
    const croppedImage = getCropData();
    const file = await base64ToFile(
      croppedImage,
      originalFile?.name,
      originalFile?.type
    );
    onUpload(file);
    onCancel();
    inputRef.current.value = "";
  };

  const onSkip = () => {
    onUpload(originalFile);
    onCancel();
    inputRef.current.value = "";
  };

  return (
    <>
      <input type="file" hidden ref={inputRef} onChange={onChange} />
      <Dialog
        maxWidth="sm"
        open={profileImage != null}
        onClose={() => setProfileImage(null)}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-dark m-0">Crop your profile</h4>
            <Close
              style={{
                padding: "0.375rem",
                height: "2rem",
                width: "2rem",
                backgroundColor: "white",
                borderRadius: "9999px",
                color: "#6c757d",
                cursor: "pointer",
                boxShadow:
                  "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)",
                border: "1px solid #e5e7eb",
              }}
              onClick={() => setProfileImage(null)}
            />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview=".img-preview"
            src={profileImage}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCrop} className="bg-primary text-white">
            Crop
          </Button>
          <Button
            onClick={onSkip}
            variant="info"
            style={{ background: "#117a8b" }}
            className="text-white"
          >
            Skip Cropping
          </Button>
          <Button
            onClick={onCancel}
            style={{ background: "gray" }}
            className="text-white"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageCropper;
