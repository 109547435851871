import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { TiImage } from "react-icons/ti";

import Header from "../../../components/Headers/DefaultHeader";
import SearchField from "../../../components/SearchFields";
import Selector from "../../../components/SearchFields/Selector";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import ArticleSkeleton from "../../../components/Skeleton/Article";
import CustomPagination from "../../Pagination/pagination";
import ArticleItem from "./ArticleItem";
import * as articleAction from "../../../store/actions/articleActions";
import * as Const from "../../../utilities";

const Articles = (props) => {
  const {
    loading,
    getArticles,
    articles: articlesData,
    deleteArticle,
    trashArticle,
    auth,
  } = props;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [articles, setArticles] = useState(articlesData);
  const [stores, setStores] = useState([]);
  const [pagination, setPagination] = useState({ start: 0, end: 10 });
  const [sort, setSort] = useState({ orderBy: "", order: "" });
  const [selectedArticle, setSelectedArticle] = useState(null);

  const skeletons = Array.from({ length: 6 });

  const { order, orderBy } = sort;
  const { start, end } = pagination;

  useEffect(() => {
    getArticles({ deleted: true });
    handleStoreNames();
  }, []);

  useEffect(() => {
    setArticles(articlesData);
  }, [articlesData]);

  const onConfirmDelete = () => {
    deleteArticle(selectedArticle?._id, auth?.token);
    setDeleteDialog(false);
    setSelectedArticle(null);
    setRestoreDialog(false);
  };

  const onConfirmRestore = () => {
    trashArticle(selectedArticle?._id, { deleted: false }, auth?.token);
    setDeleteDialog(false);
    setSelectedArticle(null);
    setRestoreDialog(false);
  };

  const paginationHandler = (start, end) => {
    setPagination({ start, end });
  };

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setSelectedArticle(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setSelectedArticle(data);
    }
  };

  const handleStoreNames = () => {
    const filterStores = [];
    articles.map((e) =>
      e.owner?.storeName ? filterStores.push(e.owner) : null
    );
    const stores = Array.from(new Set(filterStores.map((a) => a._id))).map(
      (id) => {
        return filterStores.find((a) => a._id === id);
      }
    );
    setStores(stores);
  };

  const nameSearch = (value) => {
    if (value) {
      let searchText = value.toLowerCase();
      const filterArticles = articles.filter(
        (article) => article.name.toLowerCase().indexOf(searchText) !== -1
      );
      setArticles(filterArticles);
    } else {
      setArticles(articlesData);
    }
  };

  const changeSearch = ({ target: input }) => {
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterArticles = articles.filter((el) => {
        return el.name.toLowerCase().indexOf(searchText) >= 0;
      });
      setArticles(filterArticles);
    } else {
      setArticles(articlesData);
    }
  };

  const storeSelect = ({ target: input }) => {
    if (input.value && input.value !== "") {
      const filter = articles.filter((e) => e.owner._id === input.value);
      setArticles(filter);
    } else {
      setArticles(articlesData);
    }
  };

  const filterSelect = (value) => {
    if (value === "published") {
      const filter = articles.filter((e) => e?.status === "published");
      setArticles(filter);
    } else if (value === "pending") {
      const filter = articles.filter((e) => e?.status === "pending");
      setArticles(filter);
    } else {
      setArticles(articlesData);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const createData = (
    _id,
    cover,
    name,
    views,
    status,
    category,
    createdAt,
    slug
  ) => {
    return {
      _id,
      cover,
      name,
      status,
      statusValue: status === "pending" ? 0 : 1,
      views,
      category,
      createdAt,
      slug,
    };
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
    },
    {
      id: "status",
      numeric: false,
      label: "Status",
      sortingId: "statusValue",
    },
    { id: "views", numeric: false, label: "Views" },
    { id: "category", numeric: false, label: "Category" },
    { id: "createdAt", numeric: false, label: "Date" },
  ];

  const rows = articles.map((item) =>
    createData(
      item._id,
      item.cover,
      item.name,
      item.views,
      item?.status,
      item.category?.name,
      item.createdAt,
      item.slug
    )
  );

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Articles</h3>
              </CardHeader>
              <div className="HeaderWrapper align-items-center flex-wrap">
                <SearchField onChange={nameSearch} />
                <div style={{ width: 170 }}>
                  <Selector
                    options={Const.articleSelector}
                    onChange={filterSelect}
                    placeholder="Select status"
                  />
                </div>
              </div>
              <div className="position-relative">
                <Table className={"align-items-center table-flush"} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        <TiImage size={"20px"} />
                      </th>
                      {headCells.map((cell) => (
                        <th scope="col">
                          {cell.id ? (
                            <div
                              style={{ fontSize: "14px" }}
                              onClick={(event) =>
                                handleRequestSort(
                                  event,
                                  cell?.sortingId ?? cell.id
                                )
                              }
                              className="d-flex align-items-center cursor-pointer"
                            >
                              {cell.label}{" "}
                              {orderBy === cell.id ||
                              orderBy === cell.sortingId ? (
                                <div className="d-flex flex-column ml-1">
                                  <i
                                    className="fas fa-play"
                                    style={{
                                      fontSize: "8px",
                                      transform: "rotate(-90deg)",
                                      visibility:
                                        (orderBy === cell.id ||
                                          orderBy === cell.sortingId) &&
                                        order === "asc"
                                          ? "visible"
                                          : "hidden",
                                    }}
                                  />
                                  <i
                                    className="fas fa-play"
                                    style={{
                                      fontSize: "8px",
                                      transform: "rotate(90deg)",
                                      visibility:
                                        (orderBy === cell.id ||
                                          orderBy === cell.sortingId) &&
                                        order === "desc"
                                          ? "visible"
                                          : "hidden",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center ml-1">
                                  <i
                                    className="fas fa-play mb-1"
                                    style={{
                                      marginLeft: "2px",
                                      fontSize: "8px",
                                      transform: "rotate(-90deg)",
                                      opacity: "0.3",
                                    }}
                                  />
                                  <i
                                    className="fas fa-play"
                                    style={{
                                      fontSize: "8px",
                                      transform: "rotate(90deg)",
                                      opacity: "0.3",
                                      marginLeft: "2px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <pre style={{ fontSize: "14px" }}>{cell.label}</pre>
                          )}
                        </th>
                      ))}
                      <th style={{ fontSize: "14px" }} scope="col">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      skeletons.map(() => <ArticleSkeleton />)
                    ) : rows.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="text-center">
                          Record not found
                        </td>
                      </tr>
                    ) : (
                      stableSort(rows, getComparator(order, orderBy))
                        .slice(start, end)
                        .map((article) => (
                          <ArticleItem
                            article={article}
                            handleActions={handleActions}
                          />
                        ))
                    )}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <CustomPagination
                    showPerPage={10}
                    paginationHandler={paginationHandler}
                    total={articles.length}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {deleteDialog && (
        <ConfirmationModal
          title={"Are you sure you want to delete?"}
          onClose={() => setDeleteDialog(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {restoreDialog && (
        <ConfirmationModal
          title={"Are you sure you want to restore?"}
          onClose={() => setRestoreDialog(false)}
          onConfirm={onConfirmRestore}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    articles: state.article.articles,
    loading: state.article.loading,
    errorMsg: state.article.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteArticle: (id, token) =>
      dispatch(articleAction.deleteArticle(id, token)),
    trashArticle: (id, payload, token) =>
      dispatch(articleAction.trashArticle(id, payload, token)),
    getArticles: (query) => dispatch(articleAction.getArticle(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
