import React from "react";
import { profilePlaceholder } from "../../../utilities";
const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Circle,
} = require("react-google-maps");

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const {
        item = "direction",
        client,
        vendor,
        shippingAddress,
      } = this.props;

      const clientLocation = shippingAddress
        ? shippingAddress?.location?.coordinates
        : client?.location?.coordinates;
      const vendorLocation = vendor?.location?.coordinates;

      if (item === "direction") {
        /* eslint-disable no-undef */
        const DirectionsService = new google.maps.DirectionsService();

        DirectionsService.route(
          {
            /* eslint-disable no-undef */
            origin: new google.maps.LatLng(
              clientLocation?.[1],
              clientLocation?.[0]
            ),
            /* eslint-disable no-undef */
            destination: new google.maps.LatLng(
              vendorLocation?.[1],
              vendorLocation?.[0]
            ),
            /* eslint-disable no-undef */
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              this.setState({ directions: result });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      }
    },
  })
)((props) => {
  const {
    item = "direction",
    client,
    vendor,
    shippingAddress,
    directions,
  } = props;
  const profiles = [client, vendor];

  const clientLocation = shippingAddress
    ? shippingAddress?.location?.coordinates
    : client?.location?.coordinates;
  const vendorLocation = vendor?.location?.coordinates;

  return (
    <GoogleMap
      defaultZoom={item === "direction" ? 7 : 9}
      defaultCenter={
        /* eslint-disable no-undef */
        new google.maps.LatLng(
          item === "direction" ? clientLocation?.[1] : vendorLocation?.[1],
          item === "direction" ? clientLocation?.[0] : vendorLocation?.[0]
        )
      }
      options={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {directions && item === "direction" && (
        <>
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: "#00059f",
                strokeOpacity: 1,
                strokeWeight: 10,
              },
            }}
          />
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: "#2c2cff",
                strokeOpacity: 1,
                strokeWeight: 8,
              },
            }}
          />
          {profiles.map((profile, index) => {
            const location = profile?.location?.coordinates;
            return (
              <Marker
                key={index}
                position={{ lat: location?.[1], lng: location?.[0] }}
                icon={{
                  url: profile.profilePic ?? profilePlaceholder,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
            );
          })}
        </>
      )}
      {item === "circle" && (
        <Circle
          center={{ lat: vendorLocation?.[1], lng: vendorLocation?.[0] }}
          radius={20 * 1609.34}
          options={{
            strokeColor: "#4285F4",
            strokeOpacity: 0.35,
            strokeWeight: 2,
            fillColor: "#4285F4",
            fillOpacity: 0.35,
          }}
        />
      )}
    </GoogleMap>
  );
});

export default Map;
