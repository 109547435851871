import React from "react";
import GoogleMap from "../GoogleMap";
import { icons } from "../CustomizedActivity";
import {
  formatDate,
  getDistanceFromLatLonInMiles,
} from "../../../../utilities";

const Map = (props) => {
  const { type, text, client, vendor, order, date } = props ?? {};

  const shippingAddress = order?.shippingAddressInfo;
  const userLat = shippingAddress
    ? shippingAddress?.location?.coordinates?.[1] || 0
    : client?.location?.coordinates?.[1] || 0;
  const userLon = shippingAddress
    ? shippingAddress?.location?.coordinates?.[0] || 0
    : client?.location?.coordinates?.[0] || 0;
  const vendorLat = vendor?.location?.coordinates?.[1] || 0;
  const vendorLon = vendor?.location?.coordinates?.[0] || 0;

  const distance = getDistanceFromLatLonInMiles(
    vendorLat,
    vendorLon,
    userLat,
    userLon
  );

  const googleMapsUrl = `https://www.google.com/maps/dir/${shippingAddress?.address}/${vendor?.storeAddress}`;

  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        {icons?.[type]?.icon && icons?.[type]?.icon}
        <div className="detail-wrapper">
          <div className="mb-2">
            <div>
              <div className="detail">{text}</div>
              <a
                target="_blank"
                class="text-blue-500 text-base"
                href={googleMapsUrl}
              >
                {vendor?.storeAddress}
              </a>
              <p className="text-gray-400 italic text-base font-normal">
                Distance: {distance.toFixed(2)} Miles
              </p>
              <div className="date">{formatDate(date)}</div>
            </div>
            <span>
              <a
                href={googleMapsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue text-nowrap"
              >
                View on Google Maps
              </a>
            </span>
          </div>
          <GoogleMap
            client={client}
            vendor={vendor}
            shippingAddress={shippingAddress}
          />
        </div>
      </div>
    </div>
  );
};

export default Map;
