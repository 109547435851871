import React from "react";
import placeholder from "../../../assets/img/Placeholder.png";

const ProductItem = (props) => {
  const { product, handleActions } = props;

  const convertDate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  };

  return (
    <tr key={product._id}>
      <td>
        <img
          src={
            product?.productPic[0]?.url
              ? product?.productPic[0]?.url
              : placeholder
          }
          alt="Product image"
          style={{
            width: "70px",
            height: "70px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            border: "2px double #eee",
            padding: "2px",
          }}
        />
      </td>
      <td>
        <div dangerouslySetInnerHTML={{ __html: product.name }} />
      </td>
      <td>{product.sku}</td>
      <td>
        <span
          style={{
            textDecoration: "line-through",
            color: "#fb6340",
          }}
        >
          ${product.price} <br />
        </span>
        <span style={{ color: "green" }}>
          ${product.finalPrice} <br />
        </span>
      </td>
      <td>{convertDate(product.createdAt)}</td>
      <td className="text-right">
        <a
          onClick={() => handleActions(product, "restore")}
          className="text-primary cursor-pointer"
        >
          Restore{" | "}
        </a>
        <a
          onClick={() => handleActions(product, "delete")}
          className="text-primary cursor-pointer"
        >
          Delete
        </a>
      </td>
    </tr>
  );
};

export default ProductItem;
