import { objectToQueryParams } from "../../utilities";
import API, { HeadersOrigin, patch, post } from "./../lib/Api";

export async function products(token, query) {
  const params = objectToQueryParams(query);
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/products${params}`, { headers });
}
export async function getMyProducts(user) {
  return API.get(`/products/${user}`, { headers: HeadersOrigin });
}

export async function addView(id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/products/view/${id}`, { headers });
}

export async function addToStore(id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.get(`/products/store/${id}`, { headers });
}

export async function deleteProduct(id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.delete(`/products/${id}`, { headers });
}

export async function addProduct(product, token) {
  const headers = { Authorization: `Bearer ${token}`, ...HeadersOrigin };
  return API.post("/products", product, { headers });
}
export async function updateProduct(id, product, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return patch(`/products/${id}`, product, headers);
}

export async function addFlag(id, payload, token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.post(`/products/flag/${id}`, payload, { headers });
}

export async function trashProduct(id, payload, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return post(`/products/trash/${id}`, payload, headers);
}

export async function changeStatus(id, status, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return patch(`/products/change-status/${id}`, { status }, headers);
}
