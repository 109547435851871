import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import { TiImage } from "react-icons/ti";
import { connect } from "react-redux";
import { NativeSelect } from "@material-ui/core";

import ProductList from "./ProductList";
import Paginator from "../Pagination";
import Header from "./../../components/Headers/DefaultHeader";
import SearchField from "./../../components/SearchFields";
import SelectorField from "./../../components/SearchFields/Selector";
import BootstrapInput from "../../components/UI/BootstrapInput";
import ShowEntries from "../../components/SearchFields/ShowEntries";
import DialogModal from "../../components/Modal/DialogModal";
import Modal from "../../components/Modal/ProductModal";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import * as productActions from "./../../store/actions/productActions";
import * as categoryActions from "./../../store/actions/categoryActions";
import { showMessageError } from "../../utilities/errorHandle";
import Loading from "../../utilities/loading";
import * as Const from "../../utilities";
import "../Product/Products.scss";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: this.props.product.products,
      modalIsOpen: false,
      product: {},
      imageModal: {},
      searching: false,
      page: 1,
      perPage: 10,
      flagDialog: false,
      selectedProduct: null,
      note: null,
      deleteDialog: false,
      sortBy: "date",
      orderBy: "desc",
    };
  }

  componentDidMount() {
    const { token, role } = this.props.auth;
    if (role === "vendor") {
      const { user } = this.props.auth;
      this.props.getMyProducts(user);
    } else {
      this.props.getProducts(token, { deleted: "false" });
    }
    this.setState({ products: this.props.product.products });
    const id = this.props.history.location.search?.split("=")?.[1];
    const index = this.props.product.products.findIndex(
      (article) => article._id === id
    );
    if (index !== -1) {
      const page = Math.floor(index / 10) + 1;
      this.setState({ page });
    }
    setTimeout(() => {
      Const.scrollTo(id, 150);
    }, 1000);
    if (token) this.props.getCategories({ deleted: "false" }, token);
  }

  handlePageChange = (page) => {
    this.setState({ page });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.product.products !== prevState.products &&
      !prevState.searching
    ) {
      return { products: nextProps.product.products };
    }

    if (nextProps?.product?.loading !== prevState?.product?.loading) {
      return { loading_: nextProps?.product?.loading };
    }

    if (nextProps?.product?.error !== prevState?.product?.error) {
      if (
        nextProps?.product?.error &&
        nextProps?.product?.error.toString() != ""
      ) {
        showMessageError(nextProps?.product?.error.toString());
      }
      return { errorMsg: nextProps?.product?.error };
    }

    return null;
  }

  openModal = (product) => {
    const { _id } = product;
    const imageModal = product.productPic?.[0]?.img?.split("public/")?.pop();
    this.setState({ modalIsOpen: true, product, imageModal });
    const { token } = this.props.auth;
    // this.props.addView(_id, token);
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false, deleteDialog: false });
  };

  handleAddFlag = (product) => {
    const { selectedProduct, note } = this.state;
    const { token } = this.props.auth;
    if (product) {
      this.props.addFlag(product?._id, { flag: !product?.flag, note }, token);
    } else {
      this.props.addFlag(
        selectedProduct?._id,
        { flag: !selectedProduct?.flag, note },
        token
      );
    }
    this.setState({ flagDialog: false });
  };

  handleDelete = (selectedProduct) => {
    this.setState({ selectedProduct, deleteDialog: true });
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ modalIsOpen: false });
  };

  onConfirmDelete = () => {
    const { selectedProduct } = this.state;
    const { token } = this.props.auth;
    this.props.trashProduct(selectedProduct._id, { deleted: true }, token);
    this.closeModal();
  };

  handleAddProduct = () => {
    const { role } = this.props.auth;
    if (role === "vendor") {
      this.props.history.push("/vendor/add-product");
    } else {
      this.props.history.push("/dashboard/add-product");
    }
  };

  onChangeLimit = (perPage) => {
    this.setState({ perPage });
  };

  nameSearch = (searchText) => {
    const { products } = this.props.product;
    if (searchText) {
      if ("stonerem".match(searchText?.toLowerCase())) {
        const filterProducts = products.filter((product) => !product?.owner);
        this.setState({ products: filterProducts, searching: true });
      } else {
        const searchRegex = new RegExp(searchText, "i");
        const filterProducts = products.filter((product) =>
          [
            product?.name,
            product?.description,
            product?.sku,
            product?.owner?.storeName,
            product?.owner?.contact,
            product?.owner?.storeAddress,
            product?.category?.keywords,
            product?.category?.description,
          ].some((field) => field && searchRegex.test(field))
        );
        this.setState({ products: filterProducts, searching: true });
      }
    } else {
      this.setState({
        products: this.props.product.products,
        searching: false,
      });
    }
  };

  categorySelect = (value) => {
    const { products } = this.props.product;
    if (value) {
      const filter = products.filter(
        (e) => e?.category && e?.category?._id === value
      );
      this.setState({ products: filter, searching: true });
    } else {
      this.setState({
        products: this.props.product.products,
        searching: false,
      });
    }
  };

  colorSelect = (val) => {
    const { products } = this.props.product;
    if (val) {
      const filter = products.filter((e) => e.major_color === val);
      this.setState({ products: filter, searching: true });
    } else {
      this.setState({
        products: this.props.product.products,
        searching: false,
      });
    }
  };
  changeSearch = ({ target: input }) => {
    // const products = this.props.product.products;
    // if (input.value && input.value !== "") {
    //   let searchText = input.value.toLowerCase();
    //   const filterProducts = products.filter(el => {
    //     return (
    //       el.name.toLowerCase().indexOf(searchText) >= 0 ||
    //       // el.category.indexOf(searchText) >= 0 ||
    //       el.color.toLowerCase().indexOf(searchText) >= 0
    //     );
    //   });
    //   this.setState({ products: filterProducts });
    // } else {
    //   this.setState({ products: this.props.product.products });
  };
  changeSearchText = ({ target: input }) => {
    const products = this.props.product.products;
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProduct = products.filter((el) => {
        let searchValue = el.name.toLowerCase();

        return el.name.toLowerCase().indexOf(searchText) >= 0;
      });
      this.setState({ products: filterProduct });
    } else {
      this.setState({ products: this.props.product.products });
    }
  };
  filterByStatus = (val) => {
    const { products } = this.props.product;
    if (val) {
      const filter = products.filter((e) => val === e.status);
      this.setState({ products: filter, searching: true });
    } else {
      this.setState({ products, searching: false });
    }
  };
  handleEdit = (productId) => {
    this.props.history.push("/dashboard/add-product", productId);
  };
  onSort = (sortBy, orderBy) => {
    const { products } = this.props.product;
    if (sortBy === "views") {
      if (orderBy === "asc") {
        products.sort((a, b) => b.views - a.views);
        this.setState({ products, sortBy, orderBy: "desc", searching: true });
      } else if (orderBy === "desc") {
        products.sort((a, b) => a.views - b.views);
        this.setState({ products, sortBy, orderBy: "asc", searching: true });
      }
    } else if (sortBy === "date") {
      if (orderBy === "asc") {
        products.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        this.setState({ products, sortBy, orderBy: "desc", searching: true });
      } else if (orderBy === "desc") {
        products.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        this.setState({ products, sortBy, orderBy: "asc", searching: true });
      }
    }
  };

  changeStatus = (data, status) => {
    const { auth, changeStatus } = this.props;
    changeStatus(data._id, status, auth?.token);
  };

  renderSortLabel = (label, sort) => {
    const { sortBy, orderBy } = this.state;
    return sort ? (
      <div
        style={{ fontSize: "14px" }}
        onClick={() => this.onSort(sort, orderBy)}
        className="d-flex align-items-center cursor-pointer"
      >
        {label}{" "}
        {sortBy === sort ? (
          <div className="d-flex flex-column ml-1">
            <i
              className="fas fa-play"
              style={{
                fontSize: "8px",
                transform: "rotate(-90deg)",
                visibility:
                  sortBy === sort && orderBy === "asc" ? "visible" : "hidden",
              }}
            />
            <i
              className="fas fa-play"
              style={{
                fontSize: "8px",
                transform: "rotate(90deg)",
                visibility:
                  sortBy === sort && orderBy === "desc" ? "visible" : "hidden",
              }}
            />
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center ml-1">
            <i
              className="fas fa-play mb-1"
              style={{
                marginLeft: "2px",
                fontSize: "8px",
                transform: "rotate(-90deg)",
                opacity: "0.3",
              }}
            />
            <i
              className="fas fa-play"
              style={{
                fontSize: "8px",
                transform: "rotate(90deg)",
                opacity: "0.3",
                marginLeft: "2px",
              }}
            />
          </div>
        )}
      </div>
    ) : (
      <div
        style={{ fontSize: "14px", whiteSpace: "pre" }}
        className="d-flex align-items-center"
      >
        {label}
      </div>
    );
  };

  render() {
    const { products, page, perPage } = this.state;
    const { role } = this.props.auth;

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Products</h3>
                </CardHeader>
                <div className="HeaderWrapper align-items-center flex-wrap">
                  <ShowEntries onChange={this.onChangeLimit} />
                  <SearchField onChange={this.nameSearch} />
                  <SelectorField
                    options={this.props.category.categories?.map((cat) => ({
                      label: cat?.name,
                      value: cat?._id,
                    }))}
                    placeholder="Select Category"
                    onChange={this.categorySelect}
                  />
                  <div style={{ width: 150 }}>
                    <NativeSelect
                      input={<BootstrapInput />}
                      onChange={(event) => {
                        const val = event.target.value;
                        this.colorSelect(val);
                      }}
                    >
                      <option value="">Select color</option>
                      {Const.colors?.map((option) => (
                        <option key={option?.label} value={option?.value}>
                          {option?.label}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>

                  <Button onClick={this.handleAddProduct} type="button">
                    Add Product
                  </Button>
                </div>
                <div className="position-relative">
                  <Loading
                    width={70}
                    height={70}
                    loading={this.props.loading_}
                    className="kb-loading-table"
                  />
                  <Table
                    className={`${
                      this.props.loading_ ? "kb-overlay" : ""
                    } "align-items-center mainTable table-flush table table-sm"`}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">
                          <TiImage size={"20px"} />
                        </th>
                        <th scope="col">{this.renderSortLabel("NAME")}</th>
                        <th scope="col">
                          {this.renderSortLabel("DESCRIPTION")}
                        </th>
                        <th scope="col">{this.renderSortLabel("SKU")}</th>
                        <th scope="row">
                          {this.renderSortLabel("STATUS")}
                          <div style={{ width: 150 }}>
                            <NativeSelect
                              input={<BootstrapInput />}
                              onChange={(event) => {
                                const val = event.target.value;
                                this.filterByStatus(val);
                              }}
                            >
                              <option value="">Select status</option>
                              {Const.productStatusOptions?.map((option) => (
                                <option
                                  key={option?.label}
                                  value={option?.value}
                                >
                                  {option?.label}
                                </option>
                              ))}
                            </NativeSelect>
                          </div>
                        </th>
                        <th scope="col">
                          {this.renderSortLabel(`Flagged \ninappropriate`)}
                        </th>
                        <th scope="col">{this.renderSortLabel("NOTE")}</th>
                        <th scope="col">{this.renderSortLabel("STATUS")}</th>
                        <th scope="col">
                          {this.renderSortLabel(`TOTAL \nSTOCK`)}
                        </th>
                        <th scope="col">
                          {this.renderSortLabel("AVAILABLE \nSTOCK")}
                        </th>
                        <th scope="col">{this.renderSortLabel("PRICE")}</th>
                        <th scope="col">
                          {this.renderSortLabel("TAXONOMIES")}
                        </th>
                        <th scope="col">
                          {this.renderSortLabel("VIEWS", "views")}
                        </th>
                        <th scope="col">
                          {this.renderSortLabel("PUBLISHED DATE", "date")}
                        </th>
                        <th scope="col">{this.renderSortLabel("STORE")}</th>
                        <th scope="col">
                          {this.renderSortLabel("CATEGORY META KEYWORDS")}
                        </th>
                        <th scope="col">
                          {this.renderSortLabel("CATEGORY META DESCRIPTION")}
                        </th>
                        <th scope="col">{this.renderSortLabel("ACTIONS")}</th>
                      </tr>
                    </thead>
                    <ProductList
                      products={products}
                      role={role}
                      handleAddFlag={(selectedProduct) => {
                        if (selectedProduct?.flag) {
                          this.handleAddFlag(selectedProduct);
                        } else {
                          this.setState({ flagDialog: true, selectedProduct });
                        }
                      }}
                      openModal={this.openModal}
                      handleDelete={this.handleDelete}
                      handleEdit={this.handleEdit}
                      changeStatus={this.changeStatus}
                      start={startIndex}
                      end={endIndex}
                      history={this.props.history}
                    />
                  </Table>
                </div>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Paginator
                      page={page}
                      perPage={perPage}
                      total={products.length}
                      onPageChange={this.handlePageChange}
                    />
                  </nav>
                </CardFooter>
              </Card>
              <Modal
                modalIsOpen={this.state.modalIsOpen}
                closeModal={this.closeModal}
                product={this.state.product}
                image={this.state.imageModal}
                handleDelete={this.handleDelete}
                handleEdit={this.handleEdit}
              />
            </div>
          </Row>
        </Container>
        {this.state.deleteDialog && (
          <ConfirmationModal
            title="Are you sure you want to delete?"
            onClose={() => this.setState({ deleteDialog: false })}
            onConfirm={this.onConfirmDelete}
          />
        )}
        {this.state.flagDialog && (
          <DialogModal
            onClose={() => this.setState({ flagDialog: false })}
            onConfirm={() => this.handleAddFlag()}
            title={"Flag an inappropriate"}
          >
            <textarea
              className="form-control"
              onChange={(e) => this.setState({ note: e.target.value })}
            />
          </DialogModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    product: state.product,
    category: state.category,
    loading_: state.product.loading,
    errorMsg: state.product.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (query, token) =>
      dispatch(categoryActions.getCategories(query, token)),
    getProducts: (token, query) =>
      dispatch(productActions.getProducts(token, query)),
    addView: (id, token) => dispatch(productActions.addView(id, token)),
    getMyProducts: (user) => dispatch(productActions.getMyProducts(user)),
    changeStatus: (id, status, token) =>
      dispatch(productActions.changeStatus(id, status, token)),
    trashProduct: (id, payload, token) =>
      dispatch(productActions.trashProduct(id, payload, token)),
    addFlag: (id, payload, token) =>
      dispatch(productActions.addFlag(id, payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
