import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

import DefaultHeader from "../Headers/DefaultHeader";
import NotificationItem from "./NotificationItem";
import { AntSwitch } from "../UI/Switch";
import * as userActions from "../../store/actions/userActions";
import { errorResponse } from "../../store/sagas/auth";

export const Index = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const {
    fetchingNotification,
    notificationCount,
    notifications,
    notificationsQuery,
  } = useSelector((state) => state.user);

  const [showAll, setShowAll] = useState(false);
  const [socket, setSocket] = useState(null);
  const { page, hasMoreData } = notificationsQuery;
  const query = window.location.search;
  const type = query?.split("type=").at(-1);

  // useEffect(() => {
  // setSocket(io(process.env.REACT_APP_API_ENDPOINT));
  // }, []);

  useMemo(() => {
    if (auth?.token) {
      if (showAll) {
        dispatch(
          userActions.getNotifications(
            auth?.token,
            { page: 1, limit: 10, status: "ALL", type },
            true
          )
        );
      }
    }
  }, [showAll, type]);

  useMemo(() => {
    if (!showAll) {
      if (auth?.token) {
        dispatch(
          userActions.getNotifications(
            auth?.token,
            { page: 1, limit: 10, status: "UNREAD", type },
            true
          )
        );
      }
    }
  }, [showAll, type]);

  useEffect(() => {
    if (socket && auth.user) {
      socket.emit("newUser", auth.user);
    }
  }, [socket, auth.user]);

  // useEffect(() => {
  //   const handleNotification = () => {
  //     if (auth?.token) {
  //       getNotifications(auth?.token);
  //       unreadNotification(auth?.token);
  //     }
  //   };

  //   if (socket) {
  //     socket.on("notification", handleNotification);
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("notification", handleNotification);
  //     }
  //   };
  // }, [socket, auth?.token]);

  useEffect(() => {
    const drawer = document.getElementById("notification-list");
    if (drawer) {
      drawer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (drawer) {
        drawer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchingNotification]);

  const handleScroll = (event) => {
    const target = event.target;
    if (
      hasMoreData &&
      target.scrollHeight - target.scrollTop <= target.clientHeight + 5 &&
      !fetchingNotification
    ) {
      if (showAll) {
        dispatch(
          userActions.getNotifications(
            auth?.token,
            { page: page + 1, limit: 10, status: "ALL", type },
            false
          )
        );
      } else {
        dispatch(
          userActions.getNotifications(
            auth?.token,
            { page: page + 1, limit: 10, status: "UNREAD", type },
            false
          )
        );
      }
    }
  };

  const markAsAll = () => {
    new Promise((resolve, reject) => {
      dispatch(
        userActions.markAllNotifications({
          token: auth?.token,
          payload: { type },
          resolve,
          reject,
        })
      );
    })
      .then((res) => {
        dispatch(
          userActions.getNotifications(
            auth?.token,
            { status: "UNREAD", type },
            true
          )
        );
      })
      .catch((error) => errorResponse(error));
    dispatch(
      userActions.unreadNotificationSuccess({
        ...notificationCount,
        [type]: 0,
      })
    );
  };

  return (
    <div>
      <DefaultHeader headerPadding={true} />
      <div className="notification-container">
        <div className="section-title">Notifications</div>
        <div className="header">
          <div className="switch-wrapper">
            <AntSwitch
              checked={showAll}
              onChange={(e) => setShowAll(e.target.checked)}
              inputProps={{ "aria-label": "ant design" }}
            />
            <div className="text-sm cursor-pointer">Show all</div>
          </div>
          <div onClick={markAsAll} className="custom-link text-sm">
            Mark all as read
          </div>
        </div>
      </div>
      <h2 className="border-t-2 border-gray-200 mb-3" />
      <div
        id="notification-list"
        className="notification-list"
        style={{ overflow: "auto", height: "55vh" }}
      >
        {notifications?.map((notification, index) => (
          <NotificationItem
            key={index}
            token={auth?.token}
            notification={notification}
            type={type}
          />
        ))}
      </div>
      <div style={{ height: "20px", margin: "10px 0" }}>
        {fetchingNotification && <h4 className="font-weight-bold ml-4">Loading...</h4>}
      </div>
      {!hasMoreData && (
        <p className="font-weight-bold ml-4">No more notifications to load</p>
      )}
    </div>
  );
};

export default Index;
