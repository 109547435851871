import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { NativeSelect, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import Header from "../components/Headers/Header";
import BootstrapInput from "../components/UI/BootstrapInput";
import {
  getSalesReport,
  getDashboardCount,
} from "../store/actions/userActions";

const periodOptions = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  // { label: "Months", value: "months" },
  { label: "Year", value: "year" },
  { label: "Years", value: "years" },
];

const Index = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { salesReport, dashboardCount } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    sales: [],
    units: [],
  });
  const [period, setPeriod] = useState(periodOptions[2]?.value ?? "");

  useEffect(() => {
    const timePeriod = period;
    let query = { period: timePeriod };
    if (timePeriod == "day") {
      query = {
        ...query,
        input: startDate
          ? moment(startDate).unix() * 1000
          : moment().unix() * 1000,
      };
    } else {
      query = query;
    }
    dispatch(getSalesReport(query, token));
  }, [token, period, startDate]);

  useEffect(() => {
    if (token) dispatch(getDashboardCount(token));
  }, [token]);

  useEffect(() => {
    const timePeriod = period;
    let labels = [];
    let totalSales = [];
    let totalUnits = [];
    const currentDate = moment();

    if (timePeriod === "day") {
      // Today
      labels = [currentDate.format("ddd")];
      totalSales = [0];
      totalUnits = [0];
    } else if (timePeriod === "week") {
      // Last 7 days
      labels = Array.from({ length: 7 }, (_, i) =>
        currentDate.clone().subtract(i, "days").format("ddd")
      ).reverse();
      totalSales = Array(7).fill(0);
      totalUnits = Array(7).fill(0);
    } else if (timePeriod === "month") {
      // Current Month
      const daysInMonth = currentDate.daysInMonth();
      labels = Array.from({ length: daysInMonth }, (_, i) =>
        currentDate
          .clone()
          .date(i + 1)
          .format("D")
      );
      totalSales = Array(daysInMonth).fill(0);
      totalUnits = Array(daysInMonth).fill(0);
    } else if (timePeriod === "months") {
      // Last 12 months
      labels = Array.from({ length: 12 }, (_, i) =>
        currentDate.clone().subtract(i, "months").format("MMM YYYY")
      ).reverse();
      totalSales = Array(12).fill(0);
      totalUnits = Array(12).fill(0);
    } else if (timePeriod === "year") {
      // Each month of the current year
      labels = Array.from({ length: 12 }, (_, i) =>
        currentDate.clone().month(i).format("MMM YYYY")
      );
      totalSales = Array(12).fill(0);
      totalUnits = Array(12).fill(0);
    } else if (timePeriod === "years") {
      // All available years
      const years = [...new Set(salesReport?.map((data) => data.year))].sort();
      labels = years.map((year) => String(year));
      totalSales = Array(years.length).fill(0);
      totalUnits = Array(years.length).fill(0);
    }

    salesReport.forEach((data) => {
      let index = -1;

      if (timePeriod === "day") {
        index = labels[0] === currentDate.format("ddd") ? 0 : -1;
      } else if (timePeriod === "week") {
        const dayLabel = data.day;
        index = labels.indexOf(dayLabel);
      } else if (timePeriod === "month") {
        const dayLabel = data.dayOfMonth;
        index = labels.indexOf(String(dayLabel));
      } else if (timePeriod === "months") {
        const monthLabel = data.month;
        index = labels.indexOf(monthLabel);
      } else if (timePeriod === "year") {
        const monthLabel = data.month;
        index = labels.indexOf(monthLabel);
      } else if (timePeriod === "years") {
        const yearLabel = String(data.year);
        index = labels.indexOf(yearLabel);
      }

      if (index !== -1) {
        totalSales[index] += data.totalSales;
        totalUnits[index] += data.totalUnits;
      }
    });

    setChartData({
      labels,
      sales: totalSales,
      units: totalUnits,
    });
  }, [JSON.stringify(salesReport), period]);

  const series = [
    {
      name: "Total Sales ($)",
      data: chartData?.sales,
    },
    {
      name: "Units Sold",
      data: chartData?.units,
    },
  ];

  const getOptions = (categories) => {
    return {
      legend: {
        show: false,
      },
      grid: {},
      chart: {
        type: "area",
        height: 350,
        toolbar: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories,
      },
      yaxis: [
        {},
        {
          opposite: true,
          show: false,
        },
      ],
      tooltip: {
        enabled: true,
        shared: true,
        custom: function ({ series, dataPointIndex }) {
          const sales = series[0][dataPointIndex];
          const units = series[1][dataPointIndex];
          return `<div style="padding:10px;">
                    <strong>Total Sales:</strong> $${sales}<br />
                    <strong>Units Sold:</strong> ${units} units
                  </div>`;
        },
      },
    };
  };

  return (
    <>
      <Header dashboardCount={dashboardCount} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div>
                    {period === "day" && (
                      <TextField
                        type="date"
                        value={moment(startDate ? startDate : moment()).format(
                          "YYYY-MM-DD"
                        )}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    )}
                    <NativeSelect
                      value={period}
                      className="select mt-0 ml-2"
                      input={<BootstrapInput />}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPeriod(val);
                      }}
                    >
                      {periodOptions?.map((x) => (
                        <option value={x.value}>{x.label}</option>
                      ))}
                    </NativeSelect>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <ReactApexChart
                  options={getOptions(chartData?.labels)}
                  series={series}
                  type="area"
                  height={350}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Page visits</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Page name</th>
                    <th scope="col">Visitors</th>
                    <th scope="col">Unique users</th>
                    <th scope="col">Bounce rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">/argon/</th>
                    <td>4,569</td>
                    <td>340</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 46,53%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default Index;
