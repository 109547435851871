export const ADD_COMMISSION_SETTING = "ADD_COMMISSION_SETTING";
export const ADD_COMMISSION_SETTING_SUCCESS = "ADD_COMMISSION_SETTING_SUCCESS";
export const GET_COMMISSION_SETTING = "GET_COMMISSION_SETTING";
export const GET_COMMISSION_SETTING_SUCCESS = "GET_COMMISSION_SETTING_SUCCESS";

export const GET_ALL_DELIVERY_OPTIONS = "GET_ALL_DELIVERY_OPTIONS";
export const GET_ALL_DELIVERY_OPTIONS_SUCCESS =
  "GET_ALL_DELIVERY_OPTIONS_SUCCESS";
export const GET_ALL_DELIVERY_OPTIONS_FAILURE =
  "GET_ALL_DELIVERY_OPTIONS_FAILURE";

export const ADD_OPTION = "ADD_OPTION";
export const ADD_OPTION_SUCCESS = "ADD_OPTION_SUCCESS";
export const ADD_OPTION_FAILURE = "ADD_OPTION_FAILURE";

export const EDIT_OPTION = "EDIT_OPTION";
export const EDIT_OPTION_SUCCESS = "EDIT_OPTION_SUCCESS";
export const EDIT_OPTION_FAILURE = "EDIT_OPTION_FAILURE";

export const DELETE_OPTION = "DELETE_OPTION";
export const DELETE_OPTION_SUCCESS = "DELETE_OPTION_SUCCESS";
export const DELETE_OPTION_FAILURE = "DELETE_OPTION_FAILURE";

export const ADD_SECTION = "ADD_SECTION";
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS";
export const ADD_SECTION_FAILURE = "ADD_SECTION_FAILURE";

export const DELETE_SECTION = "DELETE_SECTION";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";
export const DELETE_SECTION_FAILURE = "DELETE_SECTION_FAILURE";

export const UPDATE_PRIORITY = "UPDATE_PRIORITY";
export const UPDATE_PRIORITY_FOR_OPTION = "UPDATE_PRIORITY_FOR_OPTION";

export const RESET_SETTING = "RESET_SETTING";

export const SETTING_FAILED = "SETTING_FAILED";

export const SET_CONFING = "SET_CONFING";
export const SET_CONFING_SUCCESS = "SET_CONFING_SUCCESS";

export const GET_CONFING = "GET_CONFING";
export const GET_CONFING_SUCCESS = "GET_CONFING_SUCCESS";

export const GET_FOOTER_MENU = "GET_FOOTER_MENU";
export const GET_FOOTER_MENU_SUCCESS = "GET_FOOTER_MENU_SUCCESS";

export const ADD_FOOTER_MENU = "ADD_FOOTER_MENU";
export const ADD_FOOTER_MENU_SUCCESS = "ADD_FOOTER_MENU_SUCCESS";

export const UPDATE_FOOTER_MENU = "UPDATE_FOOTER_MENU";
export const UPDATE_FOOTER_MENU_SUCCESS = "UPDATE_FOOTER_MENU_SUCCESS";

export const DELETE_FOOTER_MENU = "DELETE_FOOTER_MENU";
export const DELETE_FOOTER_MENU_SUCCESS = "DELETE_FOOTER_MENU_SUCCESS";

export const ADD_MENU_CONTENT = "ADD_MENU_CONTENT";
export const ADD_MENU_CONTENT_SUCCESS = "ADD_MENU_CONTENT_SUCCESS";
