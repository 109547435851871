import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardFooter, Table, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Headers/DefaultHeader";
import Loading from "../../utilities/loading";
import CustomPagination from "../Pagination/pagination";
import * as userActions from "./../../store/actions/userActions";

const Feedback = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ start: 0, end: 10 });

  const { token } = useSelector((state) => state.auth);
  const { feedbacks, loading } = useSelector((state) => state.user);

  const { start, end } = pagination;

  useEffect(() => {
    dispatch(userActions.getFeedback(token));
  }, [token]);

  const paginationHandler = (start, end) => {
    setPagination({ start, end });
  };

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Feedbacks</h3>
          </CardHeader>
          <div className="position-relative">
            <Loading
              width={70}
              height={70}
              loading={loading}
              className="kb-loading-table"
            />
            <Table
              className={`${
                loading ? "kb-overlay" : ""
              } "align-items-center table-flush"`}
              responsive
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Message</th>
                </tr>
              </thead>
              <tbody>
                {feedbacks?.length === 0 ? (
                  <tr>
                    <td colSpan={12} className="text-center">
                      Record not found
                    </td>
                  </tr>
                ) : (
                  feedbacks?.slice(start, end)?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {data?.firstName} {data?.lastName}
                        </td>
                        <td>{data?.email}</td>
                        <td>{data?.message}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>

          <CardFooter className="py-4">
            <nav aria-label="...">
              <CustomPagination
                showPerPage={10}
                paginationHandler={paginationHandler}
                total={feedbacks?.length ?? 0}
              />
            </nav>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default Feedback;
