import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Chip,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

import DialogModal from "../../components/Modal/DialogModal";
import * as orderActions from "../../store/actions/orderActions";
import {
  allStatus,
  feeCalculator,
  formatDate,
  OrderStatus,
} from "../../utilities";

const OrderList = ({ history, orders, className }) => {
  const dispatch = useDispatch();
  const [orderItem, setOrderItem] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.setting);

  const handleClickActivity = (orderId) => {
    history.push(`/dashboard/orders/activity/${orderId}`, orderId);
  };

  const onApproveCancelRequest = async () => {
    const { value: comment, isConfirmed } = await Swal.fire({
      html: `<h2 clas="text-dark">Admin reason for approving cancellation</h2><h5>(For internal use only)</h5>`,
      input: "textarea",
      showCancelButton: true,
      cancelButtonColor: "gray",
      confirmButtonColor: "#007BFF",
      reverseButtons: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });
    if (isConfirmed) {
      dispatch(
        orderActions.approveCancelOrder({
          orderItemId: orderItem?._id,
          comment,
          token,
        })
      );
    }
    setShowCancelRequestModal(false);
  };

  return (
    <TableBody className={className}>
      {orders?.map((item) => {
        const addons = item?.addons ?? [];
        const addonTotal = addons?.reduce(
          (total, item) => total + item?.totalPrice,
          0
        );

        const marketingFee = feeCalculator({
          percent: config?.marketingFee,
          total: item?.totalPrice,
          addonTotal,
        });
        const maintenanceFee = feeCalculator({
          percent: config?.maintenanceFee,
          total: item?.totalPrice,
          addonTotal,
        });
        const technologyFee = feeCalculator({
          percent: config?.technologyFee,
          total: item?.totalPrice,
          addonTotal,
        });
        const workerFund = feeCalculator({
          percent: config?.workerFund,
          total: item?.totalPrice,
          addonTotal,
        });

        const orderTotal = (item?.totalPrice + (addonTotal || 0)).toFixed(2);

        const vendorOrderTotal = (
          (orderTotal || 0) -
          (marketingFee + maintenanceFee + technologyFee + workerFund)
        ).toFixed(2);

        const grossProfit = Math.abs(
          Math.floor(
            orderTotal > parseInt(vendorOrderTotal)
              ? orderTotal - parseInt(vendorOrderTotal)
              : parseInt(vendorOrderTotal) - orderTotal
          )
        );

        return (
          <TableRow key={item._id}>
            <TableCell
              style={{ minWidth: 120, maxWidth: 120 }}
              className="text-blue cursor-pointer text-nowrap"
              onClick={() => handleClickActivity(item?._id)}
            >
              #{item?.orderNo}
            </TableCell>
            <TableCell style={{ minWidth: 106, maxWidth: 106 }}>
              <span style={{ color: "#8e2b14", fontSize: "16px" }}>
                {item?.user?.firstName || item?.user?.email}
                <br />
                {item?.user?.lastName}
              </span>
            </TableCell>
            <TableCell style={{ minWidth: 172, maxWidth: 172 }}>
              {item?.shippingAddressInfo?.address}
            </TableCell>
            <TableCell style={{ minWidth: 140, maxWidth: 140 }}>
              {item?.shippingAddressInfo?.phone}
            </TableCell>
            <TableCell style={{ minWidth: 140, maxWidth: 140 }}>
              {item?.owner?.storeName}
            </TableCell>
            <TableCell style={{ minWidth: 140, maxWidth: 140 }}>
              {item?.owner?.email}
            </TableCell>
            <TableCell style={{ minWidth: 140, maxWidth: 140 }}>
              {item?.owner?.contact}
            </TableCell>
            <TableCell style={{ minWidth: 123, maxWidth: 123 }}>
              <span
                style={{
                  color: "#fb6340",
                  fontSize: "15px",
                }}
              >
                <b> ${orderTotal}</b> <br />
              </span>
              Via PayPal
            </TableCell>
            <TableCell style={{ minWidth: 140, maxWidth: 140 }} align="center">
              <span
                style={{
                  color: "#fb6340",
                  fontSize: "15px",
                }}
              >
                <b> ${grossProfit}</b> <br />
              </span>
            </TableCell>
            <TableCell
              style={{ minWidth: 120, maxWidth: 120 }}
              className="text-center"
            >
              {allStatus.map(
                (s) =>
                  item?.status === s.status && (
                    <Chip
                      style={{ background: s.color, color: "white" }}
                      label={s?.label}
                    />
                  )
              )}
            </TableCell>
            <TableCell style={{ minWidth: 100, maxWidth: 100 }}>
              <Button
                className="bg-secondary"
                onClick={() => {
                  setShowNotes(true);
                  setOrderItem(item);
                }}
              >
                Notes
              </Button>
            </TableCell>
            <TableCell
              style={{ minWidth: 200, maxWidth: 200 }}
              className="text-center"
            >
              {formatDate(item.createdAt)}
            </TableCell>
            <TableCell
              style={{ minWidth: 90, maxWidth: 90 }}
              className="text-center"
            >
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light kb-topmenu"
                  role="button"
                  size="sm"
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {item?.status == OrderStatus.CANCELLED ? (
                    <DropdownItem className="kb-firstCapital">
                      <span className="text-underline mb-2 ml-2 text-right">
                        Order Cancelled
                      </span>
                    </DropdownItem>
                  ) : item?.cancelRequest?.approved ? (
                    <DropdownItem className="kb-firstCapital">
                      <span className="text-underline mb-2 ml-2 text-right">
                        Cancel Request Approved
                      </span>
                    </DropdownItem>
                  ) : item?.cancelRequest?.reason ? (
                    <DropdownItem className="kb-firstCapital">
                      <span
                        className="mb-2 ml-2 cursor-pointer text-right"
                        onClick={() => {
                          setOrderItem(item);
                          setShowCancelRequestModal(true);
                        }}
                      >
                        Approve Cancel Request
                      </span>
                    </DropdownItem>
                  ) : (
                    <></>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </TableCell>
          </TableRow>
        );
      })}
      {showCancelRequestModal && (
        <DialogModal
          title="Approve cancel request"
          onConfirm={onApproveCancelRequest}
          onClose={() => {
            setShowCancelRequestModal(false);
            orderItem(null);
          }}
        >
          <div>
            <label className="m-0">Reason for Cancelling:</label>
            <p>{orderItem?.cancelRequest?.reason}</p>
          </div>
        </DialogModal>
      )}
      {showNotes && (
        <DialogModal
          yesButtonText="Close"
          onClose={() => {
            setShowNotes(false);
            setOrderItem(null);
          }}
          noButton={false}
          onConfirm={() => {
            setShowNotes(false);
            setOrderItem(null);
          }}
        >
          {orderItem?.cancelRequest?.reason && (
            <div>
              <label className="m-0">Customer Reason for Cancelling: </label>
              <p className="m-0 mb-2">{orderItem?.cancelRequest?.reason}</p>
            </div>
          )}
          {orderItem?.cancelRequest?.cancelledBy?.user && (
            <>
              <b className="m-0">Order Cancelled By:</b>
              <div>
                <label className="m-0">Name:</label>
                <p className="m-0 mb-2">
                  {orderItem?.cancelRequest?.cancelledBy?.user?.firstName}{" "}
                  {orderItem?.cancelRequest?.cancelledBy?.user?.lastName}
                </p>
                <label className="m-0">Email:</label>
                <p className="m-0 mb-2">
                  {orderItem?.cancelRequest?.cancelledBy?.user?.email}
                </p>
                <label className="m-0">Comment:</label>
                <p className="m-0 mb-2">
                  {orderItem?.cancelRequest?.cancelledBy?.comment}
                </p>
              </div>
            </>
          )}
          {orderItem?.cancelRequest?.approvedBy?.user && (
            <>
              <b className="m-0">Cancellation Approved By:</b>
              <div>
                <label className="m-0">Name:</label>
                <p className="m-0 mb-2">
                  {orderItem?.cancelRequest?.approvedBy?.user?.firstName}{" "}
                  {orderItem?.cancelRequest?.approvedBy?.user?.lastName}
                </p>
                <label className="m-0">Email:</label>
                <p className="m-0 mb-2">
                  {orderItem?.cancelRequest?.approvedBy?.user?.email}
                </p>
                {orderItem?.cancelRequest?.approvedBy?.comment && (
                  <>
                    <label className="m-0">Comment:</label>
                    <p className="m-0 mb-2">
                      {orderItem?.cancelRequest?.approvedBy?.comment}
                    </p>
                  </>
                )}
              </div>
            </>
          )}
        </DialogModal>
      )}
    </TableBody>
  );
};

export default OrderList;
