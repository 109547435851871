import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardBody, Button, Row, Col, Container } from "reactstrap";

import Loading from "../../utilities/loading";
import Header from "../../components/Headers/DefaultHeader";
import * as couponActions from "../../store/actions/couponAction";
import { couponSchema } from "../../utilities/Schemas/coupon";
import { authSelector } from "../../store/reducers";
import { get } from "../../store/lib/Api";
import useDebounce from "../../hooks/useDebounce";
import { couponType, couponUserType, extractAddress } from "../../utilities";
import { errorResponse } from "../../store/sagas/auth";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const CreateCoupon = ({
  location,
  loading,
  auth,
  coupons,
  addCoupon,
  updateCoupon,
}) => {
  const couponData = coupons.find((e) => e._id === location?.state);
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const { register, formState, handleSubmit, control } = useForm({
    resolver: yupResolver(couponSchema),
    defaultValues: {
      ...couponData,
      validFrom: moment(couponData?.validFrom).format("YYYY-MM-DD"),
      validUntil: moment(couponData?.validUntil).format("YYYY-MM-DD"),
    },
  });
  const { errors } = formState;
  const [addressInput, setAddressInput] = useState("");
  const debouncedAddressValue = useDebounce(addressInput, 500);

  useEffect(() => {
    if (!debouncedAddressValue) return;

    const fetchSuggestions = async () => {
      try {
        const response = await get(
          `/address/places?address=${debouncedAddressValue}`,
          { "x-api-key": process.env.REACT_APP_X_API_KEY }
        );
        const results = response.map((prediction) =>
          extractAddress(prediction)
        );
        setSuggestions(results);
      } catch (error) {
        errorResponse(error);
      }
    };

    fetchSuggestions();
  }, [debouncedAddressValue]);

  const onSubmit = (data) => {
    const couponObj = {
      code: data?.code,
      targetUser: data?.targetUser,
      discountType: data?.discountType,
      discountValue: data?.discountValue,
      maxUsage: data?.maxUsage,
      maxUsagePerUser: data?.maxUsagePerUser,
      adminId: auth?.user,
      validLocations: data?.validLocations,
      validFrom: moment(data?.validFrom).unix() * 1000,
      validUntil: moment(data?.validUntil).unix() * 1000,
    };
    if (location?.state && couponData) {
      updateCoupon(couponData?._id, couponObj, auth?.token);
    } else {
      addCoupon(couponObj, auth?.token);
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="6" md="6" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5 position-relative">
                <div className="text-center text-muted mb-4">
                  <h2>{couponData ? "Update" : "Add"} Coupon</h2>
                </div>
                <Loading
                  width={70}
                  height={70}
                  loading={loading}
                  className="kb-loading"
                />
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-100 d-flex flex-column align-items-start"
                >
                  <TextField
                    label="Code"
                    name="code"
                    helperText={errors.code?.message}
                    error={!!errors.code?.message}
                    variant="outlined"
                    {...register("code")}
                    fullWidth
                  />
                  <Controller
                    name="targetUser"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="target-user-label">
                          Target User
                        </InputLabel>
                        <Select
                          labelId="target-user-label"
                          id="target-user-select"
                          multiple
                          value={value ?? []}
                          error={!!errors?.targetUser?.message}
                          onChange={onChange}
                          input={<OutlinedInput label="Target User" />}
                          renderValue={(selected) => {
                            const newUser = selected.includes("newUser")
                              ? "New User"
                              : "";
                            const registeredUser = selected.includes(
                              "registeredUser"
                            )
                              ? "Registered User"
                              : "";

                            return [newUser, registeredUser]
                              .filter(Boolean)
                              .join(", ");
                          }}
                          MenuProps={MenuProps}
                        >
                          {couponUserType.map((type) => (
                            <MenuItem key={type?.value} value={type?.value}>
                              <Checkbox
                                color="primary"
                                checked={value?.indexOf(type?.value) > -1}
                              />
                              <ListItemText primary={type?.label} />
                            </MenuItem>
                          ))}
                        </Select>
                        <span style={{ color: "#f44336" }}>
                          {errors?.targetUser?.message}
                        </span>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="discountType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="discount-type-label">
                          Discount Type
                        </InputLabel>
                        <Select
                          labelId="discount-type-label"
                          id="discount-type-select"
                          value={value ?? ""}
                          onChange={onChange}
                          label="Discount Type"
                          error={!!errors?.discountType?.message}
                          MenuProps={MenuProps}
                          renderValue={(option) =>
                            couponType.find((opt) => opt.value == option)?.label
                          }
                        >
                          {couponType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              <FormControlLabel
                                value={option.value}
                                control={
                                  <Radio
                                    checked={value === option.value}
                                    color="primary"
                                  />
                                }
                                label={option.label}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                        <span style={{ color: "#f44336" }}>
                          {errors?.targetUser?.message}
                        </span>
                      </FormControl>
                    )}
                  />
                  <TextField
                    type="number"
                    label="Amount"
                    className="mt-3"
                    name="discountValue"
                    helperText={errors.discountValue?.message}
                    error={!!errors.discountValue?.message}
                    variant="outlined"
                    {...register("discountValue")}
                    fullWidth
                  />
                  <TextField
                    type="number"
                    label="Max coupon use"
                    name="maxUsage"
                    className="mt-3"
                    helperText={errors.maxUsage?.message}
                    error={!!errors.maxUsage?.message}
                    variant="outlined"
                    {...register("maxUsage")}
                    fullWidth
                  />
                  <TextField
                    type="number"
                    label="Max use per 1 user"
                    name="maxUsagePerUser"
                    className="mt-3"
                    helperText={errors.maxUsagePerUser?.message}
                    error={!!errors.maxUsagePerUser?.message}
                    variant="outlined"
                    {...register("maxUsagePerUser")}
                    fullWidth
                  />
                  <Controller
                    name="validLocations"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        multiple
                        fullWidth
                        variant="outlined"
                        getOptionLabel={(option) =>
                          `${option?.line1} ${option?.city} ${option?.state} ${option?.country}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Target Locations"
                            variant="outlined"
                            fullWidth
                            className="mt-3"
                            onChange={(e) => setAddressInput(e.target.value)}
                            value={addressInput}
                            error={!!errors.validLocations?.message}
                            helperText={errors.validLocations?.message}
                          />
                        )}
                        onChange={(_, value) => {
                          onChange(value);
                          setAddressInput("");
                        }}
                        onInputChange={(_, newInputValue) => {
                          setAddressInput(newInputValue);
                        }}
                        value={value}
                        inputValue={addressInput}
                        options={suggestions ?? []}
                        filterOptions={(x) => x}
                        freeSolo
                      />
                    )}
                  />
                  <div className="w-100 d-flex justify-content-between">
                    <TextField
                      type="date"
                      label="Valid from"
                      name="validFrom"
                      className="mt-3 mr-2"
                      helperText={errors.validFrom?.message}
                      error={!!errors.validFrom?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      {...register("validFrom")}
                      fullWidth
                    />
                    <TextField
                      type="date"
                      label="Valid Until"
                      name="validUntil"
                      className="mt-3"
                      helperText={errors.validUntil?.message}
                      error={!!errors.validUntil?.message}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("validUntil")}
                      fullWidth
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      type="submit"
                      className="my-4"
                      disabled={loading}
                      color={couponData ? "success" : "primary"}
                    >
                      {couponData ? "Update" : "Add"}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: authSelector(state),
    coupons: state.coupon.coupons,
    loading: state.coupon.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoupons: () => dispatch(couponActions.getCoupons()),
    addCoupon: (coupon, token) => {
      dispatch(couponActions.addCoupon(coupon, token));
    },
    updateCoupon: (_id, coupon, token) =>
      dispatch(couponActions.updateCoupon(_id, coupon, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoupon);
