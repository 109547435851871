import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import * as settingActions from "../../../store/actions/settingActions";

const OptionRow = ({ option, token }) => {
  let dispatch = useDispatch();
  let [isSelected, setIsSelected] = useState(false);
  let [editOption, setEditOption] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [currOption, setCurrOption] = useState({
    mode: option.mode,
    price: option.price,
  });
  let handleEditChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrOption({
      ...currOption,
      [e.target.name]: e.target.value,
    });
  };
  const saveEdit = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    let obj = {
      ...currOption,
      iconSrc: option.iconSrc,
      type: option.type,
    };
    dispatch(settingActions.editOption(obj, id, token));
  };

  let setEditOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditOption((prevState) => !prevState);
  };
  let deleteOption = (id) => {
    dispatch(settingActions.deleteOption(id, token));
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to DELETE !</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              deleteOption(option._id, token);
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <tr
        key={option._id}
        className="option-box-tr"
        onClick={(e) => {
          if (
            option?.mode === "Normal Delivery" ||
            option?.mode === "Express Delivery" ||
            option?.mode === "Pickup"
          ) {
            e.stopPropagation();
          } else {
            e.preventDefault();
            setIsSelected((prevState) => !prevState);
          }
        }}
      >
        <td style={{ width: option.iconSrc ? "70%" : "auto" }}>
          <div className="d-flex justify-content-start">
            <div className="option-name">
              <div className="select-icon">
                {isSelected && <div className="selected-round"></div>}
              </div>
              {editOption && isSelected ? (
                <div className="option-title">
                  <input
                    value={currOption.mode}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleEditChange}
                    name="mode"
                    type="text"
                  />
                </div>
              ) : (
                <div className="option-title">{option.mode}</div>
              )}
            </div>
          </div>
        </td>
        <td className="option-icon-container">
          <div className="option-icon">
            <img className=" " src={option.iconSrc} alt="" />
          </div>
        </td>
        <td className="w-100">
          <div className="d-flex justify-content-end">
            <div className="d-flex flex-column justify-content-end">
              {editOption && isSelected ? (
                <div className="option-title d-flex justify-content-end mb-2 ">
                  <input
                    value={currOption.price}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleEditChange}
                    className={"price-input"}
                    name="price"
                    type="text"
                  />
                </div>
              ) : (
                <div className="option-cost">
                  {option.price === "TBD" ? option.price : `$${option.price}`}
                </div>
              )}

              <div className="d-flex justify-content-end ">
                {isSelected && (
                  <div className="d-flex ">
                    {(option.mode !== currOption.mode ||
                      option.price !== currOption.price) && (
                      <div
                        className="btn btn-sm btn-primary"
                        onClick={saveEdit.bind(this, option._id)}
                      >
                        save
                      </div>
                    )}
                    <div>
                      <svg
                        onClick={setEditOptions}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                      <svg
                        onClick={handleShow}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default OptionRow;
