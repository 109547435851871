import API, { get, HeadersOrigin, post } from "./../lib/Api";

export async function login(data) {
  const headers = {
    ...HeadersOrigin,
    "x-api-key": process.env.REACT_APP_X_API_KEY,
  };
  return API.post("/users/login", data, { headers });
}

export async function forgotPassword(data) {
  const headers = { "x-api-key": process.env.REACT_APP_X_API_KEY };
  return post("/users/forgot-password", { ...data, fromAdmin: true }, headers);
}

export async function resetPassword(data) {
  const headers = { "x-api-key": process.env.REACT_APP_X_API_KEY };
  return post("/users/reset-password", data, headers);
}

export async function getMe(token) {
  const headers = { "authorization": `Bearer ${token}` };
  return get("/users/me", headers);
}
