import swal from "sweetalert2";

export const showMessageSuccess = (msg) => {
  swal.fire({
    showCloseButton: true,
    title: "Success",
    text: msg
  });
};

export const showMessageError = (msg) => {
  swal.fire({
    showCloseButton: true,
    title: "Error",
    text: msg
  });
}

export default function showError(errors) {
  let showerror = "";

  for (const e in errors) {
    showerror += errors[e] + "\n";
  }

  swal.fire({
    showCloseButton: true,
    title: "Error",
    text: showerror
  });
}
