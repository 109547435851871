import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa";
import { EditTwoTone } from "@material-ui/icons";

import Header from "./../../components/Headers/DefaultHeader";
import CreateFooterMenu from "./CreateFooterMenu";
import H5Editor from "./H5Editor";
import {
  deleteFooterMenu,
  getFooterMenu,
} from "../../store/actions/settingActions";
import "./footerMenu.scss";

const FooterMenu = ({}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const [footerMenuType, setFooterMenuType] = useState(null);

  const { token } = useSelector((state) => state.auth);
  const { footerMenus } = useSelector((state) => state.setting);

  useEffect(() => {
    if (token) {
      dispatch(getFooterMenu(token));
    }
  }, []);

  const menuAction = async ({ menu, type, isEdit }) => {
    if (isEdit) {
      setEdit(menu);
      setFooterMenuType(type);
    } else {
      const { isConfirmed } = await Swal.fire({
        text: "Are you sure you want to delete this menu?",
        showCancelButton: true,
        cancelButtonColor: "gray",
        confirmButtonText: "Yes Confirm",
        confirmButtonColor: "#007BFF",
        reverseButtons: true,
      });
      if (isConfirmed) {
        dispatch(deleteFooterMenu({ id: menu?._id, token }));
      }
    }
  };

  const filterOutMenu = (type) => {
    return footerMenus
      ?.filter((menu) => menu?.section === type)
      .map((menu) => (
        <div className="d-flex justify-content-between align-items-center">
          <span
            className="cursor-pointer text-primary"
            onClick={() => {
              setMenuData(menu);
              setFooterMenuType("resources");
            }}
          >
            {menu.name}
          </span>
          <div>
            <EditTwoTone
              onClick={() => menuAction({ menu, type, isEdit: true })}
              fontSize="small"
              className="ml-4 cursor-pointer"
            />
            <FaTrash
              onClick={() => menuAction({ menu, type, isEdit: false })}
              fontSize="small"
              className="ml-4 cursor-pointer"
            />
          </div>
        </div>
      ));
  };

  const MenuSection = ({ menuType }) => {
    return (
      <Card className="item shadow">
        <div className="d-flex justify-content-between align-items-center">
          <CardHeader className="border-0" title={menuType} />
          <Button
            onClick={() => {
              setEdit(null);
              setFooterMenuType(menuType.toLowerCase());
            }}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </div>
        <CardContent className="py-0">
          <ul className="px-0">{filterOutMenu(menuType.toLowerCase())}</ul>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {menuData && footerMenuType ? (
          <H5Editor
            footerMenuType={footerMenuType}
            menuData={menuData}
            onClose={() => {
              setMenuData(null);
              setFooterMenuType(null);
            }}
            // <CreateFooterMenuContent
            //   edit={edit}
            //   menuData={menuData}
            //   footerMenuType={footerMenuType}
            //   onClose={() => {
            //     setMenuData(null);
            //     setFooterMenuType(null);
            //   }}
            // />
          />
        ) : (
          <Card className="shadow">
            <CardHeader className="border-0" title="Footer Menu" />
            <CardContent>
              <div className="footerMenu ml-0 mr-auto pb-4">
                <MenuSection menuType="Resources" />
                <MenuSection menuType="Company" />
                <MenuSection menuType="Legal" />
              </div>
            </CardContent>
          </Card>
        )}
      </Container>

      {!menuData && footerMenuType && (
        <CreateFooterMenu
          edit={edit}
          footerMenuType={footerMenuType}
          onClose={() => setFooterMenuType(null)}
        />
      )}
    </>
  );
};

export default FooterMenu;
