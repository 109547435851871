import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { string, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import "../Article/Articles.scss";
import {
  addFooterMenu,
  updateFooterMenu,
} from "../../store/actions/settingActions";

const CreateFooterMenu = (props) => {
  const { onClose, edit, footerMenuType } = props;
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { footerMenus } = useSelector((state) => state.setting);
  const footerMenuData = footerMenus?.find((item) => item?._id === edit?._id);

  const validationSchema = object().shape({
    name: string().required("Menu Name is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: footerMenuData?.name ?? "",
    },
  };

  const { control, handleSubmit, formState } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(formFields) {
    const { name } = formFields;
    const menuObj = {
      name,
      section: footerMenuType,
    };
    if (footerMenuData?.name) {
      dispatch(
        updateFooterMenu({
          _id: footerMenuData._id,
          payload: menuObj,
          token,
        })
      );
    } else {
      dispatch(addFooterMenu({ payload: menuObj, token }));
    }
    onClose();
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <h3 className="text-primary m-0">Add Menu</h3>
          <IconButton size="small" aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="pb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  name="name"
                  variant="outlined"
                  size="small"
                  label="Name"
                  fullWidth
                  onChange={onChange}
                />
              )}
            />
            {errors.name?.message && (
              <div className="text-danger">{errors.name?.message}</div>
            )}
          </FormGroup>

          <div className="d-flex justify-content-between gap-4 mt-4">
            <Button type="submit" fullWidth className="bg-success text-white">
              PUBLISH
            </Button>
            <Button
              type="button"
              fullWidth
              className="bg-danger text-white"
              onClick={onClose}
            >
              CANCEL
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFooterMenu;
