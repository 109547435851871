import React, { useEffect } from "react";
import { string, number, object } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Container,
  TextField,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import Header from "./../../components/Headers/DefaultHeader";
import { useDispatch, useSelector } from "react-redux";
import { getConfig, setConfig } from "../../store/actions/settingActions";

const configSchema = object().shape({
  siteTitle: string().optional(),
  siteKeywords: string().optional(),
  siteDescription: string().optional(),
  freeDeliveryDistance: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  maxDeliveryDistance: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  marketingFee: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  maintenanceFee: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  technologyFee: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  workerFund: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
});

const Config = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.setting);
  const { formState, control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(configSchema),
    defaultValues: config,
  });
  const { errors } = formState;

  useEffect(() => {
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    setValue("siteTitle", config?.siteTitle);
    setValue("siteKeywords", config?.siteKeywords);
    setValue("siteDescription", config?.siteDescription);
    setValue("freeDeliveryDistance", config?.freeDeliveryDistance);
    setValue("maxDeliveryDistance", config?.maxDeliveryDistance);
    setValue("marketingFee", config?.marketingFee);
    setValue("maintenanceFee", config?.maintenanceFee);
    setValue("technologyFee", config?.technologyFee);
    setValue("workerFund", config?.workerFund);
  }, [config]);

  const onSubmit = (data) => {
    dispatch(setConfig(data, token));
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="border-0" title="Settings"></CardHeader>
          <CardContent>
            <Container maxWidth="sm" className="ml-0 mr-auto pb-4">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex flex-column w-100"
              >
                <div
                  className="p-3 mb-3 rounded"
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  <b>Stonerem Seo Management</b>
                  <Controller
                    name="siteTitle"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="Website Title"
                        className="my-3"
                        error={!!errors?.siteTitle?.message}
                        helperText={errors?.siteTitle?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: field?.value,
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="siteKeywords"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        multiline
                        className="mb-3"
                        label="Website Seo Meta Keywords"
                        error={!!errors?.siteKeywords?.message}
                        helperText={errors?.siteKeywords?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: field?.value,
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="siteDescription"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        multiline
                        label="Website Seo Meta Description"
                        error={!!errors?.siteDescription?.message}
                        helperText={errors?.siteDescription?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: field?.value,
                        }}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="freeDeliveryDistance"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      variant="outlined"
                      label="Max Free Delivery Distance"
                      error={!!errors?.freeDeliveryDistance?.message}
                      helperText={errors?.freeDeliveryDistance?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: field?.value,
                      }}
                    />
                  )}
                />
                <Controller
                  name="maxDeliveryDistance"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Max Delivery Distance"
                      error={!!errors?.freeDeliveryDistance?.message}
                      helperText={errors?.freeDeliveryDistance?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: field?.value,
                      }}
                    />
                  )}
                />
                <Controller
                  name="marketingFee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Marketing Fee (%)"
                      error={!!errors?.marketingFee?.message}
                      helperText={errors?.marketingFee?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: field?.value,
                      }}
                    />
                  )}
                />
                <Controller
                  name="maintenanceFee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Platform Maintenance Fee (%)"
                      error={!!errors?.maintenanceFee?.message}
                      helperText={errors?.maintenanceFee?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: field?.value,
                      }}
                    />
                  )}
                />
                <Controller
                  name="technologyFee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Technology Fee (%)"
                      error={!!errors?.technologyFee?.message}
                      helperText={errors?.technologyFee?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: field?.value,
                      }}
                    />
                  )}
                />
                <Controller
                  name="workerFund"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Worker Fund (%)"
                      error={!!errors?.workerFund?.message}
                      helperText={errors?.workerFund?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: field?.value,
                      }}
                    />
                  )}
                />
                <Button
                  type="submit"
                  className="bg-primary w-fit px-4 mt-3 text-white"
                >
                  Save
                </Button>
              </form>
            </Container>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Config;
