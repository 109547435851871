import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Button,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import { TiImage } from "react-icons/ti";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Header from "./../../components/Headers/DefaultHeader.jsx";
import SearchField from "./../../components/SearchFields";
import Selector from "../../components/SearchFields/Selector";
import Modal from "../../components/Modal/ArticleModal";
import Paginator from "../Pagination";
import ArticleList from "./ArticleList";
import * as articleAction from "./../../store/actions/articleActions";
import * as categoryActions from "./../../store/actions/categoryActions";
import * as Const from "../../utilities";
import Loading from "../../utilities/loading.js";
import DialogModal from "../../components/Modal/DialogModal.jsx";
import ConfirmationModal from "../../components/Modal/ConfirmationModal.jsx";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      articles: [],
      modalIsOpen: false,
      article: {},
      imageModal: "",
      searching: false,
      page: 1,
      perPage: 10,
      order: "desc",
      orderBy: "date",
      flagDialog: false,
      selectedArticle: null,
      note: null,
      deleteDialog: false,
    };
    this.componentWillReceiveProps = null;
  }

  componentDidMount() {
    const { auth, getArticle, getCategories } = this.props;

    if (auth?.role === "admin") {
      getArticle({ deleted: false });
    } else if (auth?.user) {
      getArticle({
        memberId: auth.user,
        deleted: false,
      });
    } else {
      console.error("Auth details are missing or invalid.");
    }

    getCategories();
  }

  componentDidUpdate(prevProps) {
    const { articles, history } = this.props;

    if (prevProps.articles !== articles) {
      const searchParams = new URLSearchParams(history.location.search);
      const id = searchParams.get("id");

      if (Array.isArray(articles) && id) {
        const index = articles.findIndex((article) => article._id === id);
        if (index !== -1) {
          const page = Math.floor(index / 10) + 1;
          this.setState({ page });
        }
      }

      if (id && typeof Const.scrollTo === "function") {
        setTimeout(() => {
          Const.scrollTo(id, 150);
        }, 1000);
      }

      this.setState({ articles });
    }
  }

  paginationHandler = (page) => {
    this.setState({ page });
  };

  componentWillReceiveProps = (nextProps) => {
    const articles = nextProps.articles;
    this.setState({ articles });
  };

  openModal = (article) => {
    this.setState({ modalIsOpen: true, article });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleAddFlag = (article) => {
    const { selectedArticle, note } = this.state;
    const { token } = this.props.auth;
    if (article) {
      this.props.addFlag(article?._id, { flag: !article?.flag, note }, token);
    } else {
      this.props.addFlag(
        selectedArticle?._id,
        { flag: !selectedArticle?.flag, note },
        token
      );
    }
    this.setState({ flagDialog: false });
  };

  handleDelete = () => {
    const { selectedArticle } = this.state;
    const { token } = this.props.auth;
    this.props.trashArticle(selectedArticle._id, { deleted: true }, token);
    this.handleClose();
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleEdit = (artcleId) => {
    this.props.history.push("/dashboard/add-article", artcleId);
  };

  handleAddArticle = () => {
    this.props.history.push("/dashboard/add-article");
  };

  publishArticle = (id) => {
    const { token } = this.props.auth;
    this.props.publishArticle(id, token);
  };

  nameSearch = (value) => {
    const articles = this.props.articles;
    if (value) {
      const searchRegex = new RegExp(value, "i");
      const filterArticles = articles.filter((article) =>
        [
          article?.name,
          article?.owner?.email,
          article?.owner?.firstName,
          article?.owner?.lastName,
          article?.owner?.address,
        ].some((field) => field && searchRegex.test(field))
      );
      this.setState({ articles: filterArticles, page: 1, searching: true });
    } else {
      this.setState({ articles: this.props.articles, searching: false });
    }
  };

  filterSelect = (value) => {
    const articles = this.props.articles;
    if (value === "published") {
      const filter = articles.filter((e) => e.status == "published");
      this.setState({ articles: filter, page: 1, searching: true });
    } else if (value === "pending") {
      const filter = articles.filter((e) => e.status == "pending");
      this.setState({ articles: filter, page: 1, searching: true });
    } else {
      this.setState({ articles: this.props.articles, searching: false });
    }
  };

  categorySelect = (value) => {
    const articles = this.props.articles;
    if (value) {
      const filter = articles.filter(
        (e) => e?.category && e?.category?._id === value
      );
      this.setState({ articles: filter, page: 1 });
    } else {
      this.setState({
        articles: this.props.articles,
        page: 1,
      });
    }
  };

  handleArticle = () => {
    this.props.history.push(`/dashboard/add-article`);
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  createData(
    _id,
    cover,
    name,
    flag,
    note,
    status,
    views,
    likes,
    category,
    createdAt,
    priority,
    publishDate,
    slug,
    owner
  ) {
    return {
      _id,
      cover,
      name,
      flag,
      note,
      status,
      views,
      likes,
      category,
      createdAt,
      statusValue: status === "pending" ? 0 : 1,
      priority,
      publishDate,
      slug,
      owner,
    };
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    if (this.props.loading == true) {
      return;
    } else {
      if (!result.destination) {
        // dropped outside the list
        return;
      }

      const articles = this.reorder(
        this.state.articles,
        result.source.index,
        result.destination.index
      );

      this.props.getArticlesSuccess(articles);
      let list = [];
      for (const article of articles) {
        list.push(article._id);
      }
      const { token } = this.props.auth;
      this.props.updateArticlePriority(list, token);
    }
  };

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  render() {
    const { articles, stores, order, orderBy, page, perPage } = this.state;

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;

    const headCells = [
      {
        id: "name",
        numeric: false,
        label: "Name",
      },
      { label: `Flag an \n inappropriate` },
      { label: "Note" },
      {
        id: "status",
        numeric: false,
        label: "Status",
        sortingId: "statusValue",
      },
      { id: "priority", numeric: true, label: "Priority" },
      { id: "views", numeric: true, label: "Views" },
      { id: "likes", numeric: true, label: "Likes" },
      { label: "Category" },
      { label: "Writer Email" },
      { label: "Writer Name" },
      { label: "Writer Address" },
      { id: "publishDate", numeric: false, label: "Published Date" },
      { id: "createdAt", numeric: false, label: "Created Date" },
    ];

    const rows = articles.map((item) =>
      this.createData(
        item._id,
        item.cover,
        item.name,
        item.flag,
        item?.note ?? "",
        item.status,
        item.views,
        item.likes,
        item.category?.name,
        item.createdAt,
        item.priority,
        item.publishDate,
        item.slug,
        item.owner
      )
    );

    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Articles</h3>
                </CardHeader>
                <div className="HeaderWrapper align-items-center flex-wrap">
                  <SearchField onChange={this.nameSearch} />
                  <div style={{ width: 170 }}>
                    <Selector
                      options={Const.articleSelector}
                      onChange={this.filterSelect}
                      placeholder="Select status"
                    />
                  </div>
                  <div style={{ width: 170 }}>
                    <Selector
                      options={this.props.categories}
                      onChange={this.categorySelect}
                      placeholder="Select category"
                    />
                  </div>
                  <Button onClick={this.handleArticle} type="button">
                    Add Article
                  </Button>
                </div>
                <div className="position-relative">
                  <Loading
                    width={70}
                    height={70}
                    loading={this.props.loading}
                    className="kb-loading-table"
                  />
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="Table">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Table
                            className={`${
                              this.props.loading ? "kb-overlay" : ""
                            } "align-items-center table-flush"`}
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" className="text-center">
                                  <TiImage size={"20px"} />
                                </th>
                                {headCells.map((cell, index) => (
                                  <th scope="col" key={index}>
                                    {cell.id ? (
                                      <div
                                        style={{ fontSize: "14px" }}
                                        onClick={(event) =>
                                          this.handleRequestSort(
                                            event,
                                            cell?.sortingId ?? cell.id
                                          )
                                        }
                                        className="d-flex align-items-center cursor-pointer"
                                      >
                                        {cell.label}{" "}
                                        {orderBy === cell.id ||
                                        orderBy === cell.sortingId ? (
                                          <div className="d-flex flex-column ml-1">
                                            <i
                                              className="fas fa-play"
                                              style={{
                                                fontSize: "8px",
                                                transform: "rotate(-90deg)",
                                                visibility:
                                                  (orderBy === cell.id ||
                                                    orderBy ===
                                                      cell.sortingId) &&
                                                  order === "asc"
                                                    ? "visible"
                                                    : "hidden",
                                              }}
                                            />
                                            <i
                                              className="fas fa-play"
                                              style={{
                                                fontSize: "8px",
                                                transform: "rotate(90deg)",
                                                visibility:
                                                  (orderBy === cell.id ||
                                                    orderBy ===
                                                      cell.sortingId) &&
                                                  order === "desc"
                                                    ? "visible"
                                                    : "hidden",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div className="d-flex flex-column justify-content-center align-items-center ml-1">
                                            <i
                                              className="fas fa-play mb-1"
                                              style={{
                                                marginLeft: "2px",
                                                fontSize: "8px",
                                                transform: "rotate(-90deg)",
                                                opacity: "0.3",
                                              }}
                                            />
                                            <i
                                              className="fas fa-play"
                                              style={{
                                                fontSize: "8px",
                                                transform: "rotate(90deg)",
                                                opacity: "0.3",
                                                marginLeft: "2px",
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          fontSize: "14px",
                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {cell.label}
                                      </div>
                                    )}
                                  </th>
                                ))}
                                <th></th>
                                <th style={{ fontSize: "14px" }} scope="col">
                                  ACTIONS
                                </th>
                              </tr>
                            </thead>
                            <ArticleList
                              articles={this.stableSort(
                                rows,
                                this.getComparator(order, orderBy)
                              ).slice(startIndex, endIndex)}
                              publishArticle={this.publishArticle}
                              openModal={this.openModal}
                              handleAddFlag={(selectedArticle) => {
                                if (selectedArticle?.flag) {
                                  this.handleAddFlag(selectedArticle);
                                } else {
                                  this.setState({
                                    flagDialog: true,
                                    selectedArticle,
                                  });
                                }
                              }}
                              handleDelete={(selectedArticle) =>
                                this.setState({
                                  deleteDialog: true,
                                  selectedArticle,
                                })
                              }
                              searching={this.state.searching}
                              loading={this.props.loading}
                              history={this.props.history}
                            />
                          </Table>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Paginator
                      page={page}
                      perPage={perPage}
                      onPageChange={this.paginationHandler}
                      total={articles?.length}
                    />
                  </nav>
                </CardFooter>
              </Card>
              <Modal
                modalIsOpen={this.state.modalIsOpen}
                closeModal={this.closeModal}
                article={this.state.article}
                handleDelete={(selectedArticle) =>
                  this.setState({
                    deleteDialog: true,
                    selectedArticle,
                    modalIsOpen: false,
                  })
                }
                handleEdit={this.handleEdit}
              />
            </div>
          </Row>
        </Container>
        {this.state.deleteDialog && (
          <ConfirmationModal
            title="Are you sure you want to delete?"
            onClose={this.handleClose}
            onConfirm={this.handleDelete}
          />
        )}
        {this.state.flagDialog && (
          <DialogModal
            onClose={() => this.setState({ flagDialog: false })}
            onConfirm={() => this.handleAddFlag()}
            title={"Flag an inappropriate"}
          >
            <textarea
              className="form-control"
              onChange={(e) => this.setState({ note: e.target.value })}
            />
          </DialogModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    articles: state.article.articles,
    categories: state.category.categories?.map((category) => ({
      label: category?.name,
      value: category?._id,
    })),
    loading: state.article.loading,
    errorMsg: state.article.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trashArticle: (id, payload, token) =>
      dispatch(articleAction.trashArticle(id, payload, token)),
    publishArticle: (id, token) =>
      dispatch(articleAction.publishArticle(id, token)),
    getArticle: (query) => dispatch(articleAction.getArticle(query)),
    getArticlesSuccess: (data) =>
      dispatch(articleAction.getArticlesSuccess(data)),
    updateArticlePriority: (query, token) =>
      dispatch(articleAction.updateArticlePriority(query, token)),
    addFlag: (id, payload, token) =>
      dispatch(articleAction.addFlag(id, payload, token)),
    getCategories: () => dispatch(categoryActions.getCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
