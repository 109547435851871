import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import DomPurify from "dompurify";
import moment from "moment";
import Swal from "sweetalert2";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import Comments from "./Comments";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import {
  deleteComment,
  getOneArticle,
  trashArticle,
} from "../../store/actions/articleActions";
import "./Articles.scss";
import { profilePlaceholder } from "../../utilities";

const ArticleContent = (props) => {
  const {
    auth,
    article,
    match,
    directContent,
    videos,
    images,
    history,
    deleteComment,
    getOneArticle,
  } = props;
  const slug = match.params.slug;
  const dispatch = useDispatch();
  const content = directContent ?? article;
  const [profilePic, setProfilePic] = useState(
    content?.owner?.profilePic ? content?.owner?.profilePic : auth?.profilePic
  );
  const [id, setId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (slug) getOneArticle(slug);
  }, [slug]);

  const onDelete = () => {
    deleteComment(id, auth?.token);
    setId("");
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleEdit = (articleId) => {
    history.push("/dashboard/add-article", articleId);
    setAnchorEl(null);
  };

  const handleDelete = async (articleId) => {
    setAnchorEl(null);
    const { isConfirmed } = await Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to delete this article?`,
      confirmButtonText: "Yes, I confirm",
      showCancelButton: true,
      cancelButtonColor: "gray",
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonColor: "#007BFF",
    });
    if (isConfirmed) {
      dispatch(trashArticle(articleId, { deleted: true }, auth?.token));
      history.push("/dashboard/articles");
    }
  };

  const isOpen = Boolean(anchorEl);

  const description = DomPurify.sanitize(content?.description);

  return (
    <div className={`article-content ${!directContent ? "main" : ""}`}>
      <div className={`${!directContent ? "container" : ""}`}>
        {directContent ? (
          <h1 className="mt-4 mb-4">Article preview</h1>
        ) : (
          <Button
            className="mb-4 bg-primary text-white"
            onClick={() => history.goBack()}
          >
            Go back
          </Button>
        )}
        <div onClick={handleOpenPopover} className="name cursor-pointer">
          {content?.name}
        </div>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Button
            onClick={() => handleEdit(content?._id)}
            className="bg-dark text-white"
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDelete(content?._id)}
            className="bg-danger text-white"
          >
            Delete
          </Button>
        </Popover>
        <div className="mt-3">
          <div className="w-full text-lg text-slate-800">
            <img
              style={{
                minWidth: 50,
                minHeight: 50,
                maxWidth: 50,
                maxHeight: 50,
                borderRadius: "50%",
                boxShadow: "0 2px 5px 0 rgba(0,0,0,0.5)",
              }}
              src={profilePic}
              onError={() => setProfilePic(profilePlaceholder)}
            />{" "}
            By{" "}
            {content?.owner
              ? `${content?.owner?.firstName} ${content?.owner?.lastName}`
              : auth?.name}
            {" — "} Published on{" "}
            {moment(content?.publishDate ?? content?.updatedAt).format(
              "MMMM DD, YYYY"
            )}
          </div>
        </div>
        {content?.cover && (
          <div className="w-100 poster-image">
            <img
              height="100%"
              width="100%"
              className="rounded-xl"
              src={content?.cover?.url}
              loading="lazy"
            />
          </div>
        )}
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {(images ?? content?.images)?.map((data, index) => {
          const description = DomPurify.sanitize(data?.description);

          return (
            <div className="mt-5" key={index}>
              <div className="w-100 media-image">
                <img
                  height="100%"
                  width="100%"
                  className="rounded-xl"
                  src={data?.image?.url}
                  alt={data.title}
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                  loading="lazy"
                />
              </div>
              <div className="mt-12">
                <div className="media-title text-2xl font-medium">
                  {data.title}
                </div>
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
        {(videos ?? content?.videos)?.map((data, index) => {
          const videoId = data?.video?.split("/").at(-1);
          const description = DomPurify.sanitize(data?.description);

          return (
            <div className="video-wrapper" key={index}>
              <div className="w-100">
                {videoId && (
                  <div className="article-video">
                    <LiteYouTubeEmbed id={videoId} params="controls=1" />
                  </div>
                )}
              </div>
              <div className="mt-5">
                <div className="text-2xl font-medium video-title">
                  {data.title}
                </div>
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
      {content?.comments?.length > 0 && (
        <Comments comments={content?.comments ?? []} setId={setId} />
      )}
      {id && (
        <ConfirmationModal
          title="Are you sure you want to delete this comment?"
          onClose={() => setId("")}
          onConfirm={onDelete}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    article: state.article.article,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteComment: (id, token) => dispatch(deleteComment(id, token)),
    getOneArticle: (slug) => dispatch(getOneArticle(slug)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArticleContent));
