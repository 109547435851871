/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import {
	BrowserRouter,
	Router,
	Route,
	Switch,
	Redirect,
	withRouter,
} from 'react-router-dom';
import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import VendorLayout from './layouts/Vendor';
import { connect } from 'react-redux';
import 'sweetalert2/src/sweetalert2.scss'
import ArticleContent from './views/Article/ArticleContent';
import Dashboard from './layouts/Dashboard';
// import "assets/vendor/nucleo/css/nucleo.css";
// import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// //import "assets/scss/argon-dashboard-react.scss";

class App extends Component {
	state = {};
	componentDidMount = () => {
		// Redirect
	};
	componentWillMount = () => {};

	componentDidUpdate = () => {
		// !this.props.isAuthenticated && this.props.appHistory.push(`/auth/login`);
		// alert("did update")
	};

	render() {
		return (
			<Router history={this.props.appHistory}>
				<div>
					<Switch>
						<Route exact path="/" render={(props) => <Redirect to="/auth/login" />} />
						<Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
						<Route path="/article/:slug" render={() => <ArticleContent auth={null} />} />
						<Route path="/auth" render={(props) => <AuthLayout {...props} />} />
						<Route path="/admin" render={(props) => <AdminLayout {...props} />} />
						<Route path="/vendor" render={(props) => <VendorLayout {...props} />} />
						<Route
							path="*"
							component={() => {
								// setTimeout(() => {
								//   this.props.appHistory.push("/")
								// }, 500)
								return (
									<div
										style={{
											margin: '3em',
											padding: '2em',
											textAlign: 'center',
										}}>
										<h1> 404 PAGE NOT FOUND </h1>
									</div>
								);
							}}
						/>
					</Switch>
				</div>
			</Router>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
