import { objectToQueryParams } from "../../utilities";
import API, { get, HeadersOrigin } from "./../lib/Api";

export async function orders(token, query) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  const params = objectToQueryParams(query);
  return get(`/order${params}`, headers);
}
export async function myOrders(user) {
  return API.get(`/order/${user}`, { headers: HeadersOrigin });
}

export async function orderItmes(orderId, isVendor) {
  return API.get(`/order/items/${orderId}/${isVendor}`, {
    headers: HeadersOrigin,
  });
}

export async function deleteOrder(id, token) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...HeadersOrigin,
  };
  return API.delete(`/order/${id}`, { headers });
}
