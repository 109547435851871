import * as types from "./../types/order";

export const getOrder = (token, query) => {
  return {
    type: types.GET_ORDER,
    token,
    query,
  };
};

export const getOrderSuccess = (payload) => {
  return {
    type: types.GET_ORDER_SUCCESS,
    payload,
  };
};

export const getOrderItemsSuccess = (orderItems) => {
  return {
    type: types.GET_ORDER_ITEMS_SUCCESS,
    payload: { orderItems },
  };
};

export const getOrderItemsFailure = () => {
  return {
    type: types.GET_ORDER_ITEMS_FAILURE,
  };
};

export const getMyOrder = (user) => {
  return {
    type: types.GET_MY_ORDER,
    payload: { user },
  };
};
export const getMyOrderSuccess = (orders) => {
  return {
    type: types.GET_MY_ORDER_SUCCESS,
    orders,
  };
};

export const deleteOrder = (id, token) => {
  return {
    type: types.DELETE_ORDER,
    payload: { id, token },
  };
};
export const deleteOrderSuccess = (id) => {
  return {
    type: types.DELETE_ORDER_SUCCESS,
    id,
  };
};

export const getSingleOrder = (id, token) => ({
  type: types.GET_SINGLE_ORDER,
  id,
  token,
});

export const getSingleOrderSuccess = (payload) => ({
  type: types.GET_SINGLE_ORDER_SUCCESS,
  payload,
});

export const orderRefund = (payload, token) => ({
  type: types.ORDER_REFUND,
  payload,
  token,
});

export const orderRefundSuccess = (payload) => ({
  type: types.ORDER_REFUND_SUCCESS,
  payload,
});

export const userOrderRefund = (payload, token) => ({
  type: types.USER_ORDER_REFUND,
  payload,
  token,
});

export const userOrderRefundSuccess = (payload) => ({
  type: types.USER_ORDER_REFUND_SUCCESS,
  payload,
});

export const getVendorOrders = (vendorId, token) => ({
  type: types.GET_VENDOR_ORDERS,
  vendorId,
  token,
});

export const getVendorOrdersSuccess = (payload) => ({
  type: types.GET_VENDOR_ORDERS_SUCCESS,
  payload,
});

export const getUserOrders = (userId, token) => ({
  type: types.GET_USER_ORDERS,
  userId,
  token,
});

export const getUserOrdersSuccess = (payload) => ({
  type: types.GET_USER_ORDERS_SUCCESS,
  payload,
});

export const cancelOrder = (payload, token) => ({
  type: types.CANCEL_ORDER_REQUEST,
  payload,
  token,
});

export const cancelOrderSuccess = (payload) => ({
  type: types.CANCEL_ORDER_REQUEST_SUCCESS,
  payload,
});

export const approveCancelOrder = (payload, token) => ({
  type: types.APPROVE_CANCEL_ORDER_REQUEST,
  payload,
  token,
});

export const approveCancelOrderSuccess = (payload) => ({
  type: types.APPROVE_CANCEL_ORDER_REQUEST_SUCCESS,
  payload,
});

export const resetOrder = () => ({
  type: types.RESET_ORDER,
});

export const getReviews = (token) => ({
  type: types.GET_REVIEWS,
  token,
});

export const getReviewsSuccess = (payload) => ({
  type: types.GET_REVIEWS_SUCCESS,
  payload,
});

export const createActivity = (payload, token) => {
  return {
    type: types.CREATE_ORDER_ACTIVITY,
    payload,
    token,
  };
};

export const createActivitySuccess = (payload) => ({
  type: types.CREATE_ORDER_ACTIVITY_SUCCESS,
  payload,
});

export const orderFailed = () => ({
  type: types.ORDER_FAILED,
});
