import React from "react";
import { NativeSelect } from "@material-ui/core";

import BootstrapInput from "../UI/BootstrapInput";
import "./style.css";

const ShowEnteries = ({ onChange }) => {
  return (
    <div className="d-flex align-items-center mb-0 mt-0">
      <label className="m-0">Show</label>
      <NativeSelect
        className="select mt-0 ml-2"
        input={<BootstrapInput />}
        onChange={(event) => {
          const val = event.target.value;
          onChange(val);
        }}
      >
        <option>25</option>
        <option>50</option>
        <option>55</option>
        <option>100</option>
      </NativeSelect>
    </div>
  );
};
export default ShowEnteries;
