import React from "react";
import { formatDate, profilePlaceholder } from "../../../../utilities";
import StnImage from "../../../../components/UI/Image";
import FilePreview from "../../../../components/UI/FilePreview";

const Message = (props) => {
  const { name, profilePic, message, createdAt, attachments } = props ?? {};

  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        <StnImage
          className="rounded-circle"
          src={profilePic}
          placeholder={profilePlaceholder}
          width={40}
          height={40}
          alt="User Profile"
        />
        <div className="detail-wrapper">
          <div className="detail">{name}</div>
          <div className="date">{formatDate(createdAt)}</div>
          <pre className="message pt-3 m-0 mb-2">{message}</pre>
          {attachments?.length > 0 && <label>Attachments:</label>}
          <div className="d-flex flex-wrap align-items-start" style={{ gap: 10 }}>
            {attachments?.map((file) =>
              file?.url.includes("/raw/") ? (
                <FilePreview file={file} withoutImage={true} />
              ) : (
                <img src={file?.url} style={{ width: 150, height: 150 }} />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
