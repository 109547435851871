import React, { Component } from "react";
import { Button } from "reactstrap";
import { BsEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import { LoaderHOC } from "./../../components/Hoc/LoaderHoc";

export const totalMoneySpent = (orderItems) => {
  return orderItems?.reduce((acc, item) => acc + (item?.totalPrice || 0), 0);
};

class UserList extends Component {
  render() {
    const { users, activateUser, history, start, end, onContact } = this.props;
    const visibleData = users.slice(start, end) ?? [];

    return (
      <tbody>
        {visibleData &&
          visibleData.map((user) => {
            return (
              <tr
                key={user?._id}
                id={user?._id}
                className={
                  history?.location?.search?.split("=")?.[1] === user?._id
                    ? "splash"
                    : ""
                }
              >
                <td>
                  <Link to={`/dashboard/user/${user?._id}`}>
                    <BsEyeFill className="cursor-pointer" size={15} />
                  </Link>
                </td>
                <td
                  className="cursor-pointer"
                  onClick={() => onContact(user?.email)}
                >
                  {user?.email}
                </td>
                <td>
                  {user?.firstName} {user?.lastName}
                </td>
                <td>{user?.orderItems?.length}</td>
                <td>${totalMoneySpent(user?.orderItems)}</td>
                <td>
                  <Button
                    className={`btn btn-sm ${
                      !user?.activate ? "btn-danger" : "btn-success"
                    }`}
                    onClick={() => activateUser(user._id)}
                  >
                    {!user?.activate ? "Inactive" : "Active"}
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  }
}

export default LoaderHOC("users", 5)(UserList);
