import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardFooter, Container, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Search } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  InputAdornment,
  makeStyles,
  NativeSelect,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import OrderList from "./OrderList";
import Paginator from "../Pagination";
import BootstrapInput from "../../components/UI/BootstrapInput";
import Header from "./../../components/Headers/DefaultHeader";
import * as orderActions from "./../../store/actions/orderActions";
import * as settingActions from "./../../store/actions/settingActions";
import { allStatus } from "../../utilities";
import "./order.scss";

const useStyles = makeStyles({
  tableBody: {
    height: 290,
  },
  tableHead: {
    fontWeight: 900,
  },
});

const Orders = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const { token } = useSelector((state) => state.auth);
  const { orders, totalOrders, loading } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(
      orderActions.getOrder(token, {
        page: 1,
        filterBy: JSON.stringify({ status: filterBy }),
      })
    );
    dispatch(settingActions.getConfig());
  }, [filterBy]);

  const onSearch = () => {
    dispatch(
      orderActions.getOrder(token, {
        page: 1,
        searchText,
        filterBy: JSON.stringify({ status: filterBy }),
      })
    );
  };

  const onPageChange = (page) => {
    dispatch(
      orderActions.getOrder(token, {
        page,
        searchText,
        filterBy: JSON.stringify({ status: filterBy }),
      })
    );
    setPage(page);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="search-section d-flex justify-content-between border-0">
                <h3 className="mb-0">Order</h3>
                <div className="d-flex search">
                  <TextField
                    classes={{ root: "inputWrapper" }}
                    variant="outlined"
                    size="small"
                    label="Keyword search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => e.key == "Enter" && onSearch()}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Button
                    className="w-fit"
                    onClick={onSearch}
                    style={{ borderRadius: "24px" }}
                  >
                    Search orders
                  </Button>
                </div>
              </CardHeader>
              <TableContainer
                component={Paper}
                classes={{ root: "order-table-container" }}
              >
                {loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="290px"
                  >
                    <Loader
                      type="ThreeDots"
                      className="loader"
                      color="#1171ef"
                      height={70}
                      width={70}
                    />
                  </Box>
                ) : (
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell
                          style={{ minWidth: 120, maxWidth: 120 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          ORDER ID
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 106, maxWidth: 106 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          CUSTOMER
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 172, maxWidth: 172 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          SHIPPING ADDRESS
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 140, maxWidth: 140 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          CONTACT INFO
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 200, maxWidth: 200 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          VENDOR STORE NAME
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 200, maxWidth: 200 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          VENDOR EMAIL
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 200, maxWidth: 200 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          VENDOR PHONE NO
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 123, maxWidth: 123 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          GROSS SALE
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 140, maxWidth: 140 }}
                          className="text-nowrap pt-1 pb-1"
                        >
                          GROSS PROFIT
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 150, maxWidth: 150 }}
                          className="text-nowrap pt-1 pb-1 text-center"
                        >
                          <div className="d-flex flex-column">
                            STATUS
                            <NativeSelect
                              id="demo-customized-select-native"
                              input={<BootstrapInput />}
                              value={filterBy}
                              onChange={(e) => setFilterBy(e.target.value)}
                            >
                              <option value="">Select status</option>
                              {allStatus?.map((option) => (
                                <option
                                  key={option?.label}
                                  value={option?.status}
                                >
                                  {option?.label}
                                </option>
                              ))}
                            </NativeSelect>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 100, maxWidth: 100 }}
                          className="text-nowrap pt-1 pb-1 text-center"
                        >
                          NOTES
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 200, maxWidth: 200 }}
                          className="text-nowrap pt-1 pb-1 text-center"
                        >
                          DATE
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 90, maxWidth: 90 }}
                          className="text-nowrap pt-1 pb-1 text-center"
                        >
                          ACTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <OrderList
                      className={classes.tableBody}
                      orders={orders}
                      history={history}
                    />
                  </Table>
                )}
              </TableContainer>
              {/* <div class="table-wrapper">
                <table className="table align-items-center table-flush table-container">
                  <thead className="thead-light">
                    <tr>
                      <th>ORDER ID</th>
                      <th>ORDER BY</th>
                      <th>SHIPPING ADDRESS</th>
                      <th>CONTACT INFO</th>
                      <th>GROSS SALE</th>
                      <th>GROSS PROFIT</th>
                      <th>STATUS</th>
                      <th>NOTES</th>
                      <th>DATE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <OrderList orders={orders} history={history} />
                </table>
              </div> */}
              <CardFooter className="py-2">
                <nav aria-label="...">
                  <Paginator
                    page={page}
                    perPage={perPage}
                    total={totalOrders}
                    onPageChange={onPageChange}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Orders;
