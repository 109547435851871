import React from "react";
import { formatDate, getDeliveryDate, prices } from "../../../utilities";

const Addons = ({ orderDetails, addons, product }) => {
  return (
    <div
      className="border-top mt-4 overflow-hidden"
      style={{ borderTop: "2px solid #ccc" }}
    >
      <div className="d-flex justify-content-between align-items-center py-3 px-3 bg-light">
        <div className="h3 mb-0 fw-semibold">
          Add Services{" "}
          <span className="h5 font-italic">
            {formatDate(addons?.[0]?.createdAt)}
          </span>
        </div>
      </div>
      {addons?.length &&
        addons?.map(
          (item, index) =>
            Object.values(item).length > 1 && (
              <div key={index}>
                {item?.pickupChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div className="d-flex gap-1" style={{ width: "60%" }}>
                      <div>{item?.pickupChoice?.mode}</div>
                      {orderDetails?.pickupChoiceDay && (
                        <span>
                          ({orderDetails?.pickupChoiceDay} Day
                          {orderDetails?.pickupChoiceDay > 1 && "s"})&nbsp;
                          {getDeliveryDate(orderDetails?.pickupChoiceDay)}
                        </span>
                      )}
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      ${orderDetails?.pickupChoicePrice}
                    </div>
                  </div>
                )}
                {item?.fabricationChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div style={{ width: "60%" }}>
                      <div>{item?.fabricationChoice?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      ${prices(product, item.fabricationChoice, "Fabrication")}
                    </div>
                  </div>
                )}
                {item?.edgeChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div style={{ width: "60%" }}>
                      <div>{item?.edgeChoice?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      ${item.edgeChoice.price}
                    </div>
                  </div>
                )}
                {item?.installationChoice && (
                  <div className="d-flex justify-content-between py-3 px-3">
                    <div style={{ width: "60%" }}>
                      <div>{item?.installationChoice?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>1</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      $
                      {prices(
                        product,
                        item?.installationChoice,
                        "Installation"
                      )}
                    </div>
                  </div>
                )}
                {item?.cutoutChoice?.map((option, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between py-3 px-3"
                  >
                    <div style={{ width: "60%" }}>
                      <div>(Cutout) {option?.mode}</div>
                    </div>
                    <div style={{ width: "20%" }}>{option.count}</div>
                    <div style={{ width: "20%", textAlign: "end" }}>
                      ${option?.price * option.count}
                    </div>
                  </div>
                ))}
              </div>
            )
        )}
    </div>
  );
};

export default Addons;
