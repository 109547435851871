import * as types from "./../types/auth";

export const login = (data, resolve, reject) => {
  return {
    type: types.LOGIN,
    payload: { data, resolve, reject },
  };
};
export const loginSuccess = (data) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailure = (payload) => {
  return {
    type: types.LOGIN_FAILURE,
    payload,
  };
};

export const register = (data) => {
  return {
    type: types.REGISTER,
    payload: data,
  };
};
export const registerSuccess = () => {
  return {
    type: types.REGISTER_SUCCESS,
  };
};

export const logout = () => {
  return {
    type: types.LOGOUT_USER,
  };
};

export const forgotPassword = (payload) => ({
  type: types.FORGOT_PASSWORD,
  payload,
});

export const resetPassword = (payload) => ({
  type: types.RESET_PASSWORD,
  payload,
});

export const resetAuth = () => ({
  type: types.RESET_AUTH,
});

export const getMe = (token) => ({
  type: types.GET_ME,
  token,
});

export const getMeSuccess = (payload) => ({
  type: types.GET_ME_SUCCESS,
  payload,
});

export const updateProfile = (payload, token) => ({
  type: types.UPDATE_PROFILE,
  payload,
  token,
});

export const updateProfileSuccess = (payload) => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  payload,
});
