import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";

import Item from "./Item";
import InvoiceDialog from "../../../components/Modal/InvoiceModal";
import { get } from "../../../store/lib/Api";
import * as orderActions from "../../../store/actions/orderActions";
import Loading from "../../../utilities/loading";
import RefundModal from "../../../components/Modal/RefundModal";
import DialogModal from "../../../components/Modal/DialogModal";
import { errorResponse } from "../../../store/sagas/auth";

const UserOrders = (props) => {
  const { match, history } = props ?? {};
  const params = match?.params ?? {};

  const dispatch = useDispatch();
  const [orderInfo, setOrderInfo] = useState(null);
  const [dataHtml, setDataHtml] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);

  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.order?.userOrders) ?? [];

  useEffect(() => {
    dispatch(orderActions.getUserOrders(params?.id, auth?.token));
  }, [params?.id]);

  const handleGeneratePdf = async (orderItemId) => {
    try {
      setLoading(true);
      const headers = { Authorization: `Bearer ${auth?.token}` };
      const downloadInvoice = await get(
        `/order/downloadInvoice/${orderItemId}?userType=client`,
        headers
      );

      setDataHtml(downloadInvoice);
      setLoading(false);
    } catch (error) {
      errorResponse(error);
      setLoading(false);
    }
  };

  const onApproveCancelRequest = async () => {
    const { value: comment, isConfirmed } = await Swal.fire({
      html: `<h2 clas="text-dark">Admin reason for approving cancellation</h2><h5>(For internal use only)</h5>`,
      input: "textarea",
      showCancelButton: true,
      cancelButtonColor: "gray",
      confirmButtonColor: "#007BFF",
      reverseButtons: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });
    if (isConfirmed) {
      dispatch(
        orderActions.approveCancelOrder({
          orderItemId: orderInfo?._id,
          comment,
          token: auth?.token,
        })
      );
    }
    setShowCancelRequestModal(false);
  };

  const onRefund = (payload) => {
    dispatch(
      orderActions.userOrderRefund(
        { ...payload, orderItemId: orderInfo?._id },
        auth?.token
      )
    );
    setShowRefundModal(false);
  };

  return (
    <Container className="mt-5">
      <Loading
        width={70}
        height={70}
        loading={loading}
        className="kb-loading"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/dashboard/users")}
      >
        Back
      </Button>
      {orders?.map((order, index) => {
        return (
          <React.Fragment key={index}>
            <Item
              auth={auth}
              item={order}
              setOrderInfo={setOrderInfo}
              setShowCancelRequestModal={setShowCancelRequestModal}
              setShowRefundModal={setShowRefundModal}
              handleGeneratePdf={handleGeneratePdf}
            />
            <div className="dropdown-divider" />
          </React.Fragment>
        );
      })}
      {showCancelRequestModal && (
        <DialogModal
          title="Approve cancel request"
          onConfirm={onApproveCancelRequest}
          onClose={() => setShowCancelRequestModal("")}
        >
          <div>
            <label>Reason:</label>
            <p>{orderInfo?.cancelRequest?.reason}</p>
          </div>
        </DialogModal>
      )}
      {dataHtml && (
        <InvoiceDialog dataHtml={dataHtml} onClose={() => setDataHtml(null)} />
      )}
      {showRefundModal && (
        <RefundModal
          onClose={() => setShowRefundModal(false)}
          onConfirm={onRefund}
        />
      )}
    </Container>
  );
};

export default withRouter(UserOrders);
