import React from "react";
import { NativeSelect } from "@material-ui/core";

import BootstrapInput from "../UI/BootstrapInput";
import "./style.css";

const Selector = ({ options, onChange, placeholder }) => {
  return (
    <NativeSelect
      className="select"
      input={<BootstrapInput />}
      onChange={(event) => {
        const val = event.target.value;
        onChange(val);
      }}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options?.map((option) => (
        <option key={option?.label} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </NativeSelect>
  );
};

export default Selector;
