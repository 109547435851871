/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Container,
  Alert,
} from "reactstrap";

import Header from "../../components/Headers/DefaultHeader";
import Loading from "../../utilities/loading";
import * as categoryActions from "../../store/actions/categoryActions";

const AddCategory = ({ location }) => {
  const dispatch = useDispatch();
  const { auth, category, loading_, errorMsg } = useSelector((state) => ({
    auth: state.auth,
    category: state.category,
    loading_: state.category.loading,
    errorMsg: state.category.error,
  }));

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      keywords: "",
      description: "",
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    if (location.state) {
      const { state } = location;
      const existingCategory = category.categories.find((e) => e._id === state);
      if (existingCategory) {
        setValue("name", existingCategory.name);
        setValue("keywords", existingCategory.keywords);
        setValue("description", existingCategory.description);
        setCategoryId(existingCategory._id);
        setIsEditing(true);
      }
    }
  }, [location.state, category.categories, setValue]);

  const onSubmit = (data) => {
    const { token } = auth;
    if (isEditing) {
      dispatch(categoryActions.updateCategory(categoryId, data, token));
    } else {
      dispatch(categoryActions.addCategory(data, token));
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="6" md="6" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5 position-relative">
                <div className="text-center text-muted mb-4">
                  <p>{isEditing ? "Update category" : "Add a category"}</p>
                </div>
                <Loading
                  width={70}
                  height={70}
                  loading={loading_}
                  className="kb-loading"
                />
                <Form
                  className={`${loading_ ? "kb-overlay" : ""}`}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <Input
                            placeholder="Name"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors.name && <Alert color="danger">{errors.name.message}</Alert>}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Controller
                        name="keywords"
                        control={control}
                        rules={{ required: "Keywords are required" }}
                        render={({ field }) => (
                          <Input
                            placeholder="Meta keywords (only first letter Cap) in this format: Kitchen counters, Bathroom counters"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors.keywords && <Alert color="danger">{errors.keywords.message}</Alert>}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <Input
                            type="textarea"
                            placeholder="Meta description"
                            {...field}
                          />
                        )}
                      />
                    </InputGroup>
                    {errors.description && (
                      <Alert color="danger">{errors.description.message}</Alert>
                    )}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color={isEditing ? "success" : "primary"}
                      type="submit"
                      disabled={loading_}
                    >
                      {isEditing ? "Update" : "Add"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCategory;
