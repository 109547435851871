import React from "react";
import "./style.css";
import { TextField } from "@material-ui/core";

const SearchField = ({ onChange }) => {
  return (
    <TextField
      size="small"
      type="text"
      variant="outlined"
      name="search"
      id="searchbar"
      placeholder="Search"
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default SearchField;
