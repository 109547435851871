import React, { useEffect, useState } from "react";
import { Card, Col, Container, FormGroup, Row } from "react-bootstrap";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { yupResolver } from "@hookform/resolvers/yup";

import Loading from "../../utilities/loading";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import * as userActions from "../../store/actions/userActions";
import { authSelector } from "./../../store/reducers";
import { userValidationSchema } from "../../utilities/Schemas/user";
import { get } from "../../store/lib/Api";
import useDebounce from "../../hooks/useDebounce";
import { accessFeatures } from "../../utilities";

const animatedComponents = makeAnimated();

const DEFAULT_PASSWORD = "DEFAULT_PASSWORD";

const CreateUser = ({
  userData,
  getUser,
  addUser,
  updateUser,
  auth,
  history,
  location,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (location.state) {
      getUser({ id: location.state, token: auth?.token });
    }
  }, [location]);

  useEffect(() => {
    reset({
      firstName: userData?.firstName ?? "",
      lastName: userData?.lastName ?? "",
      email: userData?.email ?? "",
      address: userData?.address ?? "",
      password: userData ? DEFAULT_PASSWORD : "",
      accesses: accessFeatures?.filter((ele) =>
        userData?.accesses?.includes(ele?.value)
      ),
    });
  }, [userData, reset]);

  const formOptions = {
    resolver: yupResolver(userValidationSchema(userData ? true : false)),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      password: "",
      address: "",
      accesses: [],
    },
  };

  const { handleSubmit, reset, setValue, formState, watch, control } =
    useForm(formOptions);

  const address = watch("address");
  const addressInput = watch("addressInput");
  const debouncedAddressValue = useDebounce(address, 500);

  const { errors, isValid, dirtyFields } = formState;

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedAddressValue.length < 3) return;
      try {
        const response = await get(
          `/address/places?address=${debouncedAddressValue}`,
          { "x-api-key": process.env.REACT_APP_X_API_KEY }
        );
        const results = response.map((prediction) => prediction.description);
        setSuggestions(results);
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address suggestions",
          icon: "error",
        });
      }
    };

    debouncedAddressValue && dirtyFields.address && fetchSuggestions();
  }, [debouncedAddressValue]);

  useEffect(() => {
    const fetchAddress = async () => {
      if (!addressInput) return;
      try {
        const response = await get(`/address/place?address=${addressInput}`, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        setValue("address", response?.[0]?.formatted_address ?? "");
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address",
          icon: "error",
        });
      }
    };

    addressInput && fetchAddress();
  }, [addressInput]);

  const onSubmit = (formFields) => {
    let createObj = {
      firstName: formFields?.firstName,
      lastName: formFields?.lastName,
      email: formFields?.email?.toLowerCase(),
      address: formFields?.address,
      accesses: formFields?.accesses?.map((access) => access?.value),
    };

    if (formFields?.password != DEFAULT_PASSWORD) {
      createObj = {
        ...createObj,
        password: formFields?.password,
      };
    }
    if (isValid) {
      if (userData) {
        updateUser(auth?.token, userData?._id, createObj);
      } else {
        addUser(auth?.token, createObj);
      }
      history.push(`/dashboard/users-with-roles`);
      reset();
    }
  };

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="8" md="12" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0 position-relative">
              <Card.Body className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>{userData ? "Update" : "Add"} a user</h2>
                </div>
                <Loading
                  width={70}
                  height={70}
                  loading={false}
                  className="kb-loading"
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">First name:</label>
                    </div>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          size="small"
                          placeholder="Firstname"
                        />
                      )}
                    />
                    {errors.firstName?.message && (
                      <div className="text-danger">
                        {errors.firstName?.message}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Last name:</label>
                    </div>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          size="small"
                          placeholder="Lastname"
                        />
                      )}
                    />
                    {errors.lastName?.message && (
                      <div className="text-danger">
                        {errors.lastName?.message}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Email:</label>
                    </div>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          size="small"
                          placeholder="Email"
                        />
                      )}
                    />
                    {errors.email?.message && (
                      <div className="text-danger">{errors.email?.message}</div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Password:</label>
                    </div>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="password"
                          variant="outlined"
                          fullWidth
                          size="small"
                          placeholder="Password"
                        />
                      )}
                    />
                    {errors.password?.message && (
                      <div className="text-danger">
                        {errors.password?.message}
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Address:</label>
                    </div>
                    <Controller
                      control={control}
                      name="address"
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={suggestions ?? []}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Address"
                              variant="outlined"
                              size="small"
                              fullWidth
                              onChange={onChange}
                            />
                          )}
                          onChange={(_, value) => {
                            onChange(value);
                            setValue("addressInput", value);
                          }}
                          value={value}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Permissions:</label>
                    </div>
                    <Controller
                      name="accesses"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          components={animatedComponents}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                          }}
                          placeholder="Select role"
                          options={accessFeatures}
                        />
                      )}
                    />
                  </FormGroup>

                  <div className="d-flex gap-6 mt-5">
                    <Button
                      className="w-100"
                      color="danger"
                      type="button"
                      onClick={() =>
                        history.push(`/dashboard/users-with-roles`)
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      className="w-100"
                      color={userData ? "success" : "primary"}
                      type="submit"
                    >
                      {userData ? "Update" : "Add"}
                    </Button>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: authSelector(state),
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (token, payload) => dispatch(userActions.addUser(token, payload)),
    getUser: (payload) => dispatch(userActions.getUser(payload)),
    updateUser: (token, id, payload) =>
      dispatch(userActions.updateUser(token, id, payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
