import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Collapse } from "@material-ui/core";

import Addons from "../Addons";
import RequirementData from "../RequirementData";
import { icons } from "../CustomizedActivity";
import { ACTIVITY_TYPE, formatDate } from "../../../../utilities";

const Expendable = (props) => {
  const {
    type,
    text,
    link,
    status,
    createdAt,
    requirements,
    orderDetails,
    addonId,
  } = props;
  const [open, setOpen] = useState({ requirements: false, addons: false });
  const addons = orderDetails?.addons ?? [];

  const handleClick = (action) =>
    setOpen({ ...open, [action]: !open?.[action] });

  const addonDetails = addons?.find((item) => item?._id === addonId);

  return (
    <div className="activity-container">
      <span className="divider" />
      <div className="wrapper">
        {icons?.[type]?.icon && icons?.[type]?.icon}
        <div className="detail-wrapper">
          <div className="expendable">
            <div className="expandable-details">
              <div>{text}</div>
              <span>{formatDate(createdAt)}</span>
            </div>
            {status === ACTIVITY_TYPE.REQUIREMENT_SUBMITTED ? (
              <div className="disabled-text">Project details submitted</div>
            ) : type === ACTIVITY_TYPE.SUBMIT_REQUIREMENT ? (
              <div className="d-flex">
                {status === ACTIVITY_TYPE.NO_REQUIREMENT ? (
                  <div className="mt-2 italic disabled-text">
                    This project has no details
                  </div>
                ) : (
                  <div className="d-flex mt-2">
                    <Link href={link}>
                      <Button className="bg-primary text-white">
                        Add Details
                      </Button>
                    </Link>
                    <Button className="btn-danger text-white">
                      No Details
                    </Button>
                  </div>
                )}
              </div>
            ) : type === ACTIVITY_TYPE.REQUIREMENT_SUBMITTED ||
              type === ACTIVITY_TYPE.MORE_REQUIREMENT_SUBMITTED ? (
              <div
                className="text-primary cursor-pointer text-nowrap"
                onClick={() => handleClick("requirements")}
              >
                View project details
              </div>
            ) : type === ACTIVITY_TYPE.NEW_ADDONS ? (
              <div
                className="text-primary cursor-pointer text-nowrap"
                onClick={() => handleClick("addons")}
              >
                View addons
              </div>
            ) : (
              <></>
            )}
          </div>
          <Collapse in={open.requirements} timeout="auto" unmountOnExit>
            {requirements && <RequirementData requirement={requirements} />}
          </Collapse>
          <Collapse in={open.addons} timeout="auto" unmountOnExit>
            {addonDetails && (
              <Addons
                orderDetails={orderDetails}
                product={orderDetails?.product}
                addons={[addonDetails]}
              />
            )}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Expendable;
